<template>
  <div class="main_fixed main korean">
    <MissionSaveModal
      :isMissionSaveModalVisible="isMissionSaveModalVisible"
      @closeModal="isMissionSaveModalVisible = false"
      @saveMission="handleSaveMission"
    />
    <MainImportantMessage
      v-if="importantMessage"
      :importantMessage="importantMessage"
    />
    <MissionListModal
      :isMissionListModalVisible="isMissionListModalVisible"
      :filteredMissionList="filteredMissionList"
      @closeModal="isMissionListModalVisible = false"
      @loadingMission="handleLoadingMission"
      @deleteMission="handleDeleteMission"
    />
    <MainHeaderVue :navigator="navigator" />
    <div class="cloud_viewer_3d" ref="cloud_viewer_3d">
      <div class="inspection_main_menu">
        <div class="inspection_site_title">시설물</div>
        <div
          class="inspection_site_content"
          v-if="selectedSite"
          :style="{ width: getWidth(selectedSite.name) }"
        >
          {{ selectedSite.name }}
        </div>
        <div class="inspection_current_map_content">
          <div class="inspection_current_map_name">선택된 맵</div>
          <div class="inspection_currnet_map_container" v-if="selectedSite.map">
            {{ selectedSite.map.createdDatetime }}
          </div>
        </div>
        <div class="inspection_button_contents">
          <div class="inspection_plan_control button" @click="planOn()">
            운행계획 및 점검제어
          </div>
          <div class="inspection_view_buttons_container">
            <div
              v-if="isLogVisible"
              class="inspection_log_button button on"
              @click="logOn()"
            ></div>
            <div
              v-else
              class="inspection_log_button button"
              @click="logOn()"
            ></div>
            <div
              v-if="isMonitorVisible"
              class="inspection_monitor_button button on"
              @click="monitorOn()"
            ></div>
            <div
              v-else
              class="inspection_monitor_button button"
              @click="monitorOn()"
            ></div>
            <div
              v-if="isSettingModalVisible"
              class="inspection_setting_button button on"
              @click="settingOn()"
            ></div>
            <div
              v-else
              class="inspection_setting_button button"
              @click="settingOn()"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-if="!isMonitorVisible"
        class="inspection_view_label input"
        style="right: calc(50% + 20px)"
      >
        3D 맵
      </div>
    </div>
    <div
      v-if="isMobile"
      class="cloud_viewer_2d"
      @touchstart="handleMouseDown"
      @touchmove="handleMouseMove"
      @touchend="handleMouseUp"
      ref="cloud_viewer_mobile_2d"
    >
      <div
        v-if="!isMonitorVisible"
        class="inspection_view_label input"
        @click.stop
      >
        2D 맵
      </div>
    </div>
    <div
      v-else
      class="cloud_viewer_2d"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      ref="cloud_viewer_2d"
    >
      <div
        v-if="!isMonitorVisible"
        class="inspection_view_label input"
        @click.stop
      >
        2D 맵
      </div>
    </div>
    <div
      class="inspection_drone_selection_container"
      @click.stop="activeDroneSelection()"
    >
      <div class="inspection_selected_drone">드론 {{ currentDroneId + 1 }}</div>
      <div class="isnpection_select_drone_image"></div>
    </div>
    <div
      v-if="
        !droneData[this.currentDroneId].state.localization ||
        !moduleData.state.localization
      "
      class="inspection_drone_localization_apply_button"
      @click.stop="setLocalizationConfig"
    >
      설정
    </div>
    <div v-if="droneSelectionFlag" class="inspection_select_drone_modal">
      <div class="inspection_drone_item" @click.stop="droneSelection(0)">
        드론 1
      </div>
      <div class="inspection_drone_item" @click.stop="droneSelection(1)">
        드론 2
      </div>
      <div class="inspection_drone_item" @click.stop="droneSelection(2)">
        드론 3
      </div>
      <div class="inspection_drone_item" @click.stop="droneSelection(3)">
        드론 4
      </div>
      <div class="inspection_drone_item last" @click.stop="droneSelection(4)">
        드론 5
      </div>
    </div>
    <div class="inspection_area_height_controller_localization"
    v-if="!droneData[this.currentDroneId].state.localization ||
          !moduleData.state.localization">
      <div class="inspection_area_currnet_height">
        범위 설정
      </div>
      <div class="inspection_area_height_area">
        <input
          :placeholder="localization_height.min"
          class="inspection_area_height_area_value"
          @blur="handleLocalizationHeight($event,'min')"
        />
        <div
          :style="{
            width: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }"
        >
          ~
        </div>
        <input
          class="inspection_area_height_area_value"
          :placeholder="localization_height.max"
          @blur="handleLocalizationHeight($event,'max')"
        />
      </div>
    </div>
    <PlanModal
      :isVisible="isPlanModalVisible"
      :isMonitorVisible="isMonitorVisible"
      :waypointList="waypoints"
      :groupList="groups"
      :currentDroneId="currentDroneId"
      :lineParams="lineParams"
      :undersideParams="undersideParams"
      :circleParams="circleParams"
      :rectangleParams="rectangleParams"
      :lowerHeight="lowerHeight"
      :upperHeight="upperHeight"
      :droneControl="droneControl"
      :droneData="droneData"
      :droneParams="droneParams"
      :fitShape="fitShape"
      :isMissionListModalVisible="isMissionListModalVisible"
      :isMissionSaveModalVisible="isMissionSaveModalVisible"
      @openMissionListModal="missionListOn"
      @openMissionSaveModel="missionSaveOn"
      @missionStateChange="handleMissionState"
      @fitShapeSelected="handleFitShape"
      @fitApplyButtonClicked="handleFitApplyButtonClicked"
      @deleteWaypoint="handleDeleteWaypoint"
      @clearWaypoint="handleClearWaypoint"
      @deleteGroup="handleDeleteGroup"
      @rotateGroup="handleRotateGroup"
      @mergeGroup="handleMergeGroup"
      @swapGroups="handleSwapGroup"
      @checkCollision="handleCheckCollision"
      @updateWaypoint="handleUpdateWaypoint"
      @heightChanged="handleHeightChanged"
      @lineParamsChanged="handleLineParamsChanged"
      @lineNFitPointsChanged="handleLineNFitPointsChanged"
      @undersideParamsChanged="handleUndersideParamsChanged"
      @undersideNBoxPointsChanged="handleUndersideNBoxPointsChanged"
      @circleParamsChanged="handleCircleParamsChanged"
      @rectangleParamsChanged="handleRectangleParamsChanged"
      @lowerHeightChanged="handleLowerHeightChanged"
      @upperHeightChanged="handleUpperHeightChanged"
      @planRTHClicked="handlePlanRTH"
      @planRTLClicked="handlePlanRTL"
      @changeArmPosition="handleArmPosition"
      @resetClicked="resetClicked"
      @clickRtl="this.droneData[currentDroneId].state.rtl = true"
      @clickRth="this.droneData[currentDroneId].state.rth = true"
      @changeWaypointNumber="handleWaypointNumber"
      @close="isPlanModalVisible = false"
    >
    </PlanModal>

    <MonitorModal
      :isVisible="isMonitorVisible"
      :droneData="droneData"
      :currentDroneId="currentDroneId"
      @close="isMonitorVisible = false"
    >
    </MonitorModal>

    <LogModal
      :isVisible="isLogVisible"
      :statusText="statusText"
      @close="isLogVisible = false"
    >
    </LogModal>

    <SettingModal
      :isVisible="isSettingModalVisible"
      :selectedDrone="selectedDrone"
      :droneList="droneList"
      :selectedModule="selectedModule"
      :droneTypeList="droneTypeList"
      :selectedDroneType="selectedDroneType"
      :droneParams="droneParams"
      :pointCloudParams="pointCloudParams"
      @getDroneTypes="getDroneTypes"
      @droneTypeChanged="handleDroneTypeChanged"
      @droneChanged="selectDrone"
      @moduleChanged="handleModuleChanged"
      @takeoffChanged="hadleTakeoffChanged"
      @waitingTimeChanged="handleWaitingTimeChanged"
      @batteryFailSafeChanged="handleBatteryFailSafeChanged"
      @changePointCloudParam="handlePointCloudParam"
      @close="isSettingModalVisible = false"
    >
    </SettingModal>
  </div>
  <MainLoadingVue ref="mainLoading" />
</template>

<script>
import Waypoint from "@/model/Waypoint.js";
import Shape from "@/model/Shape.js";
import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Drone from "@/model/Drone.js";
import DroneData from "@/model/InspectionDroneData.js";
import DroneType from "@/model/DroneType.js";
import CameraMission from "@/model/CameraMission";
import PointCloudParams from "@/model/PointCloudParams.js";

import PCReportViewer from "@/module/PointCloud/PointCloudViewInspection.js";
import WebSoketHandler from "@/module/Communication/WebSocketHandler.js";
import GroupHandler from "@/module/GroupHandler.js";
import DroneMonitor from "@/module/Drone/DroneMonitor.js";
import DroneControl from "@/module/Drone/DroneControl.js";

import "./InspectionMainMenu.scss";
import PlanModal from "./InspectionPlanModal.vue";
import MonitorModal from "./InspectionMonitorModal.vue";
import LogModal from "./InspectionLogModal.vue";
import SettingModal from "./InspectionSettingModal.vue";
import MissionSaveModal from "./InspectionMissionSaveModal.vue";
import MissionListModal from "./InspectionMissionListModal.vue";
import MainHeaderVue from "../Common/MainHeader.vue";
import MainLoadingVue from "../Common/MainLoading.vue";
import MainImportantMessage from "../Common/MainImportantMessage.vue";

import { mapState } from "vuex";
import { markRaw } from "vue";
import InspectionMission from "@/model/InspectionMission";
import { formatISO } from "date-fns";

export default {
  name: "InspectionPage",
  data() {
    return {
      importantMessage: null,
      importantMessageTimer: null,
      navigator: "Inspection",
      map: null,
      cloudViewer: null,
      droneSelectionFlag: false,
      droneTypeList: [],
      selectedDroneType: new DroneType(),
      droneList: [],
      selectedDrone: [
        new Drone(),
        new Drone(),
        new Drone(),
        new Drone(),
        new Drone(),
      ],
      droneControl: [null, null, null, null, null],
      droneMonitor: [null, null, null, null, null],
      droneData: [
        new DroneData(),
        new DroneData(),
        new DroneData(),
        new DroneData(),
        new DroneData(),
      ],
      statusText: [],
      selectedModule: new Drone(),
      moduleControl: null,
      moduleMonitor: null,
      moduleData: { state: { localization: true } },
      currentDroneId: 0,
      isPlanModalVisible: false,
      isMonitorVisible: false,
      isLogVisible: false,
      isSettingModalVisible: false,
      isMissionListModalVisible: false,
      isMissionSaveModalVisible: false,
      waypoints: [[], [], [], [], []],
      groups: [[], [], [], [], []],
      shapeCreateCounter: [0, 0, 0, 0, 0],
      waypointCreateCounter: 0,
      fitShape: null,
      webSoketHandler: null,
      droneParams: {
        takeoffHeight: 10,
        waitingTime: 1,
        batteryFailSafe: 25,
        connectionLost: 10,
      },
      pointCloudParams: new PointCloudParams(),
      currentHeight: 0,
      lineParams: {
        heightInterval: 2,
        height: 5,
        fromWall: 5,
        nFitPoints: 2,
        gimbalPitch: 0,
        direction: "left",
        isAuto: true,
      },
      undersideParams: {
        interval: 3,
        fromWall: 7,
        nBoxPoints: 8,
        direction: "long",
        isAuto: true,
        droneDimX: 1,
        droneDimY: 1,
        droneDimZ: 1,
        gimbalPitch: 90,
      }, // dimension은 setting에서 받아올 것
      circleParams: {
        interval: 30,
        startAngle: 0,
        fromWall: 7,
        direction: "inward",
        gimbalControl: true,
      },
      rectangleParams: {
        interval: 2,
        fromWall: 7,
        direction: "inward",
        gimbalControl: true,
      },
      startPoint: { x: 0, y: 0 },
      endPoint: { x: 0, y: 0 },
      localization_height : {min : 0, max : 0, limitMin : 0, limitMax : 0},
      lowerHeight: 0,
      upperHeight: 0,
      parsingCompletedListener: null,
      setCollidedWPListener: null,
      filteredMissionList: [],
    };
  },
  computed: {
    ...mapState(["user", "apiIp", "apiPort", "selectedSite", "isMobile","api"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 18 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
  },
  watch: {
    selectedDroneType: {
      handler(selectedDroneType) {
        if (selectedDroneType) {
          this.undersideParams.droneDimX = selectedDroneType.dimensionX;
          this.undersideParams.droneDimY = selectedDroneType.dimensionY;
          this.undersideParams.droneDimZ = selectedDroneType.dimensionZ;
        }
      },
    },
  },
  components: {
    PlanModal,
    MonitorModal,
    LogModal,
    SettingModal,
    MainHeaderVue,
    MissionListModal,
    MissionSaveModal,
    MainLoadingVue,
    MainImportantMessage,
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    planOn() {
      if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.$store.commit(
          "openAlert",
          "해당 드론의 Localization을 먼저 해주세요."
        );
      } else {
        this.isPlanModalVisible = !this.isPlanModalVisible;
        this.isLogVisible = false;
        this.isSettingModalVisible = false;
        this.isMissionListModalVisible = false;
        this.isMissionSaveModalVisible = false;
      }
    },
    monitorOn() {
      this.isMonitorVisible = !this.isMonitorVisible;
    },
    logOn() {
      this.isLogVisible = !this.isLogVisible;
      this.isPlanModalVisible = false;
      this.isSettingModalVisible = false;
      this.isMissionListModalVisible = false;
      this.isMissionSaveModalVisible = false;
    },
    settingOn() {
      this.isSettingModalVisible = !this.isSettingModalVisible;
      this.isMissionListModalVisible = false;
      this.isPlanModalVisible = false;
      this.isLogVisible = false;
      this.isMissionSaveModalVisible = false;
    },
    missionListOn() {
      this.isSettingModalVisible = false;
      this.isLogVisible = false;
      this.isMissionSaveModalVisible = false;
      this.isMissionListModalVisible = !this.isMissionListModalVisible;
    },
    missionSaveOn() {
      this.isMissionSaveModalVisible = !this.isMissionSaveModalVisible;
      this.isSettingModalVisible = false;
      this.isLogVisible = false;
      this.isMissionListModalVisible = false;
    },
    activeDroneSelection() {
      if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.$store.commit(
          "openAlert",
          "해당 드론의 Localization을 먼저 해주세요."
        );
      } else {
        this.droneSelectionFlag = !this.droneSelectionFlag;
      }
    },
    setLocalizationConfig() {
      let rectPoints = this.cloudViewer.getRectPoints();
      if (rectPoints.length != 0) {
        let minX = rectPoints[0].x;
        let maxX = rectPoints[3].x;
        let minY = -rectPoints[1].z;
        let maxY = -rectPoints[0].z;
        if (maxX - minX < 100) {
          let centerX = (minX + maxX) / 2;
          minX = centerX - 50;
          maxX = centerX + 50;
        }
        if (maxY - minY < 100) {
          let centerY = (minY + maxY) / 2;
          minY = centerY - 50;
          maxY = centerY + 50;
        }
        if (this.selectedDroneType.name == "Anafi") {
          this.moduleControl.localizationConfig(minX, maxX, minY, maxY, this.localization_height.min, this.localization_height.max);
          this.cloudViewer.rectClear();
          if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
          this.importantMessage = `Sirius의 초기 위치를 설정 중 입니다.`;
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
        } else {
          this.droneControl[this.currentDroneId].localizationConfig(
            minX,
            maxX,
            minY,
            maxY,
            this.localization_height.min,
            this.localization_height.max
          );
          this.cloudViewer.rectClear();
          if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
          this.importantMessage = `Drone${this.currentDroneId + 1}이 초기 위치를 설정 중 입니다.`;
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
        }
      } else {
        this.$store.commit(
          "openAlert",
          "2D 맵에서 드래그하여 드론의 초기 영역을 지정해주세요."
        );
      }
    },
    droneSelection(id) {
      this.currentDroneId = id;
      this.webSoketHandler.setDroneId(this.currentDroneId);
      this.droneSelectionFlag = false;
    },
    handleMouseDown(event) {
      if (event.button === 2) return;
      let intersectPoint;
      if (this.isMobile) {
        intersectPoint = this.cloudViewer.getInterSectionPointMobile(event);
      } else {
        intersectPoint = this.cloudViewer.getInterSectionPoint(event);
      }

      this.startPoint.x = intersectPoint.x;
      this.startPoint.y = -intersectPoint.z;

      if (this.fitShape == "Waypoint") {
        let wpRotation = this.getWpRotation(intersectPoint.x, intersectPoint.z);
        this.cloudViewer.drawWaypoint(
          this.currentDroneId,
          intersectPoint.x,
          intersectPoint.z,
          this.currentHeight,
          wpRotation
        );
        let waypoint = new Waypoint(
          intersectPoint.x,
          -intersectPoint.z,
          this.currentHeight,
          wpRotation,
          0,
          "waypoint",
          false,
          false,
          0,
          null
        );

        this.waypoints[this.currentDroneId].push(waypoint);
      } else if (this.fitShape == "Line") {
        if (this.isMobile) {
          this.cloudViewer.setStartPointLineMobile(
            intersectPoint.x,
            intersectPoint.z,
            this.currentHeight
          );
        } else
          this.cloudViewer.setStartPointLine(
            intersectPoint.x,
            intersectPoint.z,
            this.currentHeight
          );
      } else if (this.fitShape == "Underside") {
        if (this.isMobile)
          this.cloudViewer.setRegionPointsUndersideMobile(
            intersectPoint.x,
            intersectPoint.z
          );
        else
          this.cloudViewer.setRegionPointsUnderside(
            intersectPoint.x,
            intersectPoint.z
          );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.setStartPointRect(intersectPoint.x, intersectPoint.z);
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.setStartPointRect(intersectPoint.x, intersectPoint.z);
      }
    },
    handleMouseMove(event) {
      if (event.button === 2) return;
      if (this.fitShape == "Line") {
        if (this.isMobile) return;
        if (this.cloudViewer.getLinePointsCount() == 0) return;
      } else if (this.fitShape == "Underside") {
        if (this.cloudViewer.getUndersidePointsCount() == 0) return;
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        if (!this.cloudViewer.getRectClicked()) return;
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        if (!this.cloudViewer.getRectClicked()) return;
      }

      let intersectPoint;
      if (this.isMobile)
        intersectPoint = this.cloudViewer.getInterSectionPointMobile(event);
      else intersectPoint = this.cloudViewer.getInterSectionPoint(event);
      this.endPoint.x = intersectPoint.x;
      this.endPoint.y = -intersectPoint.z;

      if (this.fitShape == "Line" && !this.isMobile)
        this.cloudViewer.fitRegionLineDraw(intersectPoint.x, intersectPoint.z);
      else if (this.fitShape == "Underside" && !this.isMobile) {
        this.cloudViewer.fitRegionUndersideBorderDraw(
          intersectPoint.x,
          intersectPoint.z
        );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.fitRegionRectDraw(
          this.startPoint,
          this.endPoint,
          intersectPoint.x,
          intersectPoint.z,
          this.lowerHeight,
          this.upperHeight
        );
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.fitRegionRectDraw(
          this.startPoint,
          this.endPoint,
          intersectPoint.x,
          intersectPoint.z,
          this.lowerHeight,
          this.upperHeight
        );
      }
    },
    handleMouseUp(event) {
      if (event.button === 2) return;
      if (this.fitShape == "Line") {
        this.cloudViewer.fitRegionLineSurfaceDraw(this.lineParams.height);
      } else if (this.fitShape == "Underside") {
        this.cloudViewer.fitRegionUndersideSurfaceDraw(
          this.lowerHeight,
          this.upperHeight
        );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.setRectClicked(false);
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.setRectClicked(false);
      }
    },
    handleMissionState(boolean) {
      this.droneData[this.currentDroneId].state.mission = boolean;
    },
    getPrevPoint() {
      let prevX;
      let prevY;
      if (this.waypoints[this.currentDroneId].length === 0) {
        prevX = 0;
        prevY = 0;
      } else {
        prevX =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].getX();
        prevY =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].getY();
      }

      return { x: prevX, y: prevY };
    },
    getWpRotation(x, z) {
      let wpRotation;
      if (this.waypoints[this.currentDroneId].length == 0) {
        let deltaX = 0 - x;
        let deltaZ = 0 - z;
        if (deltaX === 0) return 0;
        let slope = deltaZ / deltaX;
        wpRotation = Math.atan(slope);
        if (deltaX < 0) wpRotation = wpRotation + Math.PI;
      } else {
        let deltaX =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].x - x;
        let deltaZ =
          -this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].y - z;
        if (deltaX === 0) return 0;
        let slope = deltaZ / deltaX;
        wpRotation = Math.atan(slope);
        if (deltaX < 0) wpRotation = wpRotation + Math.PI;
      }
      return wpRotation;
    },
    waypointReset(setFalse) {
      for (let i = 0; i < this.waypoints[this.currentDroneId].length; i++) {
        let x = this.waypoints[this.currentDroneId][i].getX();
        let z = -this.waypoints[this.currentDroneId][i].getY();
        // let y =  this.waypoints[this.currentDroneId][i].z;
        let tempGroupNum = this.waypoints[this.currentDroneId][i].getGroupNum();
        if (setFalse) this.waypoints[this.currentDroneId][i].setChecked(false);

        let wpRotation;
        if (i == 0) {
          let delta_x = 0 - x;
          let delta_z = 0 - z;
          if (delta_x === 0) wpRotation = 0;
          else {
            let slope = delta_z / delta_x;
            wpRotation = Math.atan(slope);
            if (delta_x < 0) wpRotation = wpRotation + Math.PI;
          }
          this.waypoints[this.currentDroneId][i].setYaw(wpRotation);
        } else {
          let delta_x = this.waypoints[this.currentDroneId][i - 1].getX() - x;
          let delta_z = -this.waypoints[this.currentDroneId][i - 1].getY() - z;
          if (delta_x === 0) wpRotation = 0;
          else {
            let slope = delta_z / delta_x;
            wpRotation = Math.atan(slope);
            if (delta_x < 0) wpRotation = wpRotation + Math.PI;
          }
          if (
            tempGroupNum === 0 ||
            this.waypoints[this.currentDroneId][i - 1].getGroupNum() === 0 ||
            this.waypoints[this.currentDroneId][i - 1].getGroupNum() !==
              this.waypoints[this.currentDroneId][i].getGroupNum()
          )
            this.waypoints[this.currentDroneId][i].setYaw(wpRotation);
        }
      }
    },

    messageParsingFinished() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },

    setCollidedWaypoints() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },
    handleFitShape(shape) {
      this.fitShape = shape;
      this.cloudViewer.lineClear();
      this.cloudViewer.undersideClear();
      this.cloudViewer.rectClear();
      if (shape){
        this.cloudViewer.setHeight(this.currentHeight);
        this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
        this.cloudViewer.setHeightInfoVisibe(true);
      }
      else{
        this.cloudViewer.setHeightInfoVisibe(false);
      }
    },
    handleFitApplyButtonClicked() {
      if (this.fitShape == "Line") {
        this.webSoketHandler.setLineParams(this.lineParams);
        if (this.cloudViewer.checkLineRegion()) {
          let linePoints = this.cloudViewer.getLinePoints();
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setLineData(
            linePoints,
            this.currentHeight,
            prevWP
          );
          this.webSoketHandler.send(dataToSend);
        }
      } else if (this.fitShape == "Underside") {
        this.webSoketHandler.setUndersideParams(this.undersideParams);
        if (this.cloudViewer.checkUndersideRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setUndersideDataAuto(
            this.cloudViewer.getUndersidePointsFirst(),
            this.cloudViewer.getUndersidePointsSecond(),
            prevWP,
            this.currentHeight
          );
          this.webSoketHandler.send(dataToSend);
        }
      } else if (this.fitShape == "Circle") {
        this.webSoketHandler.setCircleParams(this.circleParams);
        if (this.cloudViewer.checkRectRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setCircleData(
            prevWP,
            this.currentHeight,
            this.startPoint,
            this.endPoint
          );
          this.webSoketHandler.send(dataToSend);
        }
      } else if (this.fitShape == "Rectangle") {
        this.webSoketHandler.setRectParams(this.rectangleParams);
        if (this.cloudViewer.checkRectRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setRectData(
            prevWP,
            this.currentHeight,
            this.startPoint,
            this.endPoint
          );
          this.webSoketHandler.send(dataToSend);
        }
      }
      this.cloudViewer.lineClear();
      this.cloudViewer.undersideClear();
      this.cloudViewer.rectClear();
    },
    handleDeleteWaypoint() {
      if (this.waypoints[this.currentDroneId].length === 0) return;

      let tempArray = new Array(this.groups[this.currentDroneId].length).fill(
        0
      );

      for (
        let i = this.waypoints[this.currentDroneId].length - 1;
        i >= 0;
        i--
      ) {
        if (this.waypoints[this.currentDroneId][i].checked) {
          this.waypoints[this.currentDroneId].splice(i, 1);
        } else {
          if (this.waypoints[this.currentDroneId][i].getGroupNum() > 0)
            tempArray[
              this.waypoints[this.currentDroneId][i].getGroupNum() - 1
            ] =
              tempArray[
                this.waypoints[this.currentDroneId][i].getGroupNum() - 1
              ] + 1;
        }
      }
      this.waypointReset(true);
      this.cloudViewer.resetWaypoints(this.currentDroneId);

      if (this.groups[this.currentDroneId].length == 0) return;
      for (let i = this.groups[this.currentDroneId].length - 1; i >= 0; i--) {
        if (tempArray[i] == 0) {
          for (
            let j = this.waypoints[this.currentDroneId].length - 1;
            j >= 0;
            j--
          ) {
            if (this.waypoints[this.currentDroneId][j].getGroupNum() >= i + 1)
              this.waypoints[this.currentDroneId][j].setGroupNum(
                this.waypoints[this.currentDroneId][j].getGroupNum() - 1
              );
          }
          for (let j = 0; j < this.groups[this.currentDroneId].length; j++) {
            if (
              this.groups[this.currentDroneId][j].getGroupNum() >
              this.groups[this.currentDroneId][i].getGroupNum()
            )
              this.groups[this.currentDroneId][j].setGroupNum(
                this.groups[this.currentDroneId][j].getGroupNum() - 1
              );
          }
          this.groups[this.currentDroneId].splice(i, 1);
        }
      }
      this.groupHandler.reset(this.currentDroneId, true);
    },
    clearWaypointAll() {
      for (let i = 0; i < 5; i++) {
        this.waypoints[i] = [];
        this.groups[i] = [];
        this.droneData[i].state.waypointNumber = 0;
        this.cloudViewer.arrowLineClear(i);
      }
    },
    handleClearWaypoint() {
      this.cloudViewer.deleteTempWaypoint(this.currentDroneId);
      if (this.waypoints[this.currentDroneId].length === 0) return;
      this.waypoints[this.currentDroneId] = [];
      this.groups[this.currentDroneId] = [];
      this.droneData[this.currentDroneId].state.waypointNumber = 0;
      this.cloudViewer.arrowLineClear(this.currentDroneId);
    },
    handleDeleteGroup() {
      if (this.groups[this.currentDroneId].length === 0) return;

      this.groupHandler.delete(this.currentDroneId);

      this.waypointReset(true);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, true);
    },
    handleRotateGroup() {
      if (!this.groupHandler.rotate(this.currentDroneId)) return;
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleMergeGroup() {
      if (!this.groupHandler.merge(this.currentDroneId)) return;
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleSwapGroup(swapIdx) {
      this.groupHandler.swap(this.currentDroneId, swapIdx);
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleUpdateWaypoint() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },
    handleCheckCollision() {
      this.webSoketHandler.setDroneId(this.currentDroneId);
      let x = this.selectedDroneType.dimensionX / 1000;
      let y = this.selectedDroneType.dimensionY / 1000;
      let z = this.selectedDroneType.dimensionZ / 1000;
      this.webSoketHandler.sendWPData(this.currentDroneId, {
        x: x,
        y: y,
        z: z,
      });
    },
    handleHeightChanged(height) {
      this.currentHeight = height;
      this.cloudViewer.setHeight(height);
    },
    handleLowerHeightChanged(height) {
      if (height > this.upperHeight) {
        this.lowerHeight = height;
        this.upperHeight = height;
      } else this.lowerHeight = height;
      this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
      this.webSoketHandler.setHeightRange(this.lowerHeight, this.upperHeight);
    },
    handleUpperHeightChanged(height) {
      if (height < this.lowerHeight) {
        this.lowerHeight = height;
        this.upperHeight = height;
      } else this.upperHeight = height;
      this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
      this.webSoketHandler.setHeightRange(this.lowerHeight, this.upperHeight);
    },
    handlePlanRTH() {
      let z = this.droneParams.takeoffHeight;
      let yaw;

      if (this.waypoints[this.currentDroneId].length == 0) {
        yaw = -Math.PI;
      } else {
        yaw =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].yaw;
      }
      this.cloudViewer.drawWaypoint(this.currentDroneId, 0, 0, z, yaw);
      this.waypoints[this.currentDroneId].push(
        new Waypoint(0, 0, z, yaw, 0, "rth", false, false, 0, null)
      );
    },
    handlePlanRTL() {
      let x = this.droneData[this.currentDroneId].armPosition.x;
      let y = this.droneData[this.currentDroneId].armPosition.y;
      let z = this.droneParams.takeoffHeight;
      let yaw;

      if (this.waypoints[this.currentDroneId].length == 0) {
        yaw = -Math.PI;
      } else {
        yaw =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].yaw;
      }
      this.cloudViewer.drawWaypoint(this.currentDroneId, x, y, z, yaw);
      this.waypoints[this.currentDroneId].push(
        new Waypoint(x, y, z, yaw, 0, "rtl", false, false, 0, null)
      );
    },
    handleArmPosition() {
      this.droneData[this.currentDroneId].armPosition.x =
        this.droneData[this.currentDroneId].pose.x;
      this.droneData[this.currentDroneId].armPosition.y =
        this.droneData[this.currentDroneId].pose.y;
      this.droneData[this.currentDroneId].armPosition.yaw =
        this.droneData[this.currentDroneId].pose.yaw;
    },
    resetClicked() {
      this.handleWaypointNumber(this.currentDroneId, 0);
      this.cloudViewer.deleteTempWaypoint(this.currentDroneId);
    },
    handleWaypointNumber(droneNum, waypointNumber) {
      this.droneData[droneNum].state.waypointNumber = waypointNumber;
      for (let i = 0; i < this.waypoints[droneNum].length; i++) {
        if (i < waypointNumber) {
          this.waypoints[droneNum][i].completed = true;
        } else {
          this.waypoints[droneNum][i].completed = false;
        }
      }
      this.cloudViewer.resetWaypoints(droneNum);
    },
    handleLineParamsChanged(lineParams) {
      this.lineParams = lineParams;
    },
    handleLineNFitPointsChanged(lineParams) {
      this.lineParams = lineParams;
      this.cloudViewer.setNumLinePoints(lineParams.nFitPoints);
    },
    handleUndersideParamsChanged(undersideParams) {
      this.undersideParams = undersideParams;
    },
    handleUndersideNBoxPointsChanged(undersideParams) {
      this.undersideParams = undersideParams;
      this.cloudViewer.setUndersideNumRegionPoints(undersideParams.nBoxPoints);
    },
    handleCircleParamsChanged(circleParams) {
      this.circleParams = circleParams;
    },
    handleRectangleParamsChanged(rectangleParams) {
      this.rectangleParams = rectangleParams;
    },
    handleDroneTypeChanged(droneType) {
      if (droneType) {
        this.selectedDroneType = droneType;
      } else {
        for (let i = 0; i < 5; i++) {
          if (this.selectedDrone[i].id) this.selectDrone(i);
          if (this.selectedModule.id) this.handleModuleChanged();
        }
        this.selectedDroneType = new DroneType();
      }
    },
    selectDrone(num, drone) {
      if(this.selectedDroneType.id){
        this.checkDroneStatus(num,drone);
      }else{
        this.selectedDrone[num] = new Drone();
        this.$store.commit(
          "openAlert",
          "모듈 종류를 먼저 선택해주세요."
        );
      }
    },
    checkDroneStatus(num, drone){
      if(drone){
        this.api.getDrone(this.checkDroneStatusCallback,drone.id,num);
      }else{
        this.selectedDrone[num] = new Drone();
        this.connectDrone(num);  
      }
    },
    checkDroneStatusCallback(data, num){
      let result = data.data.result;
      if(result.droneStatus == 'disConnecting'){
        this.selectedDrone[num] = new Drone(result);
      }
      else{
        this.$store.commit(
          "openAlert",
          "이미 사용중인 아이디입니다. 아이디를 확인해주세요."
        );
        this.selectedDrone[num] = new Drone();
      }
      this.connectDrone(num);
    },
    connectDrone(num) {
      if(this.droneMonitor[num]){
        this.api.putDrone(this.droneMonitor[num].module.id,"disConnecting");
        this.droneMonitor[num].closeMqtt();
        this.droneMonitor[num] = null;
        this.droneControl[num] = null;
        this.cloudViewer.deleteDroneModel(num);
        this.cloudViewer.rectClear();
        this.droneData[num] = new DroneData();
      }
      if (this.selectedDrone[num].droneSerialNumber) {
        this.isSettingModalVisible = false;
        this.droneMonitor[num] = new DroneMonitor(
          this.selectedDrone[num],
          num
        );
        this.api.putDrone(this.droneMonitor[num].module.id,"connecting");
        this.droneMonitor[num].subConnection(this.connectionCallback);
        this.droneMonitor[num].subRtl(this.rtlCallback);
        this.droneMonitor[num].subSiriusState(this.siriusStateCallback);
        this.droneMonitor[num].subSiriusError(this.siriusErrorCallback);
        this.droneMonitor[num].subDataState(this.dataStateCallback);
        this.droneMonitor[num].subDataError(this.dataErrorCallback);
        this.droneMonitor[num].subSlamWarning(this.slamWarningCallback);
        this.droneMonitor[num].subLocalizationState(this.localizationStateCallback);
        this.droneMonitor[num].subLocalizationWarning(this.localizationWarningCallback);
        this.droneMonitor[num].subWaypointError(this.waypointErrorCallback);
        this.droneMonitor[num].subLocalPose(this.localPoseCallback);
        this.droneMonitor[num].subState(this.stateCallback);
        this.droneMonitor[num].subBattery(this.batteryCallback);
        this.droneMonitor[num].subStatusText(this.statusTextCallback);
        this.droneMonitor[num].subSensorState(this.sensorStateCallback);
        this.droneMonitor[num].subGroundSpeed(this.groundSpeedCallback);
        this.droneMonitor[num].subGpsMode(this.gpsModeCallback);
        this.droneMonitor[num].subWaypoint(this.waypointCallback);
        this.droneMonitor[num].subTempWaypoint(this.tempWaypointCallback);
        this.droneMonitor[num].subSlamState(this.slamStateCallback);
        this.droneMonitor[num].subStorage(this.storageCallback);
        this.droneMonitor[num].subDjiConnection(this.djiConnectionCallback);
        this.droneMonitor[num].subDjiModulePoseStop(
          this.djiModulePoseStopCallback
        );
        this.droneMonitor[num].readMsg();

        this.droneControl[num] = new DroneControl(
          this.droneMonitor[num].mqttClient,
          this.selectedDrone[num]
        );

        this.cloudViewer.createDroneModel(num);
      }
    },
    handleModuleChanged(selectedModule) {
      if (this.selectedDroneType.id) {
        if (selectedModule) {
          this.selectedModule = selectedModule;
        } else {
          this.selectedModule = new Drone();
        }
        this.connectModule();
      } else {
        this.selectedModule = new Drone();
        this.$store.commit(
          "openAlert",
          "모듈 종류를 먼저 선택해주세요."
        );
      }
    },
    connectModule() {
      if (this.moduleMonitor) {
        this.moduleMonitor.closeMqtt();
        this.moduleMonitor = null;
        this.moduleControl = null;
        this.api.putDrone(this.moduleMonitor.module.id,"disConnecting");
      }
      if (this.selectedModule.droneSerialNumber) {
        this.isSettingModalVisible = false;
        this.api.putDrone(this.moduleMonitor.module.id,"connecting");
        this.moduleMonitor = new DroneMonitor(
          this.selectedModule
        );
        this.moduleMonitor.subConnection(this.moduleConnectionCallback);
        this.moduleMonitor.subRtl(this.moduelRtlCallback);
        this.moduleMonitor.subSiriusState(this.moduleStateCallback);
        this.moduleMonitor.subSlamState(this.modulePoseCallback);
        this.moduleMonitor.subSiriusError(this.moduleStateErrorCallback);
        this.moduleMonitor.subDataState(this.moduleDataCallback);
        this.moduleMonitor.subDataError(this.moduleDataErrorCallback);
        this.moduleMonitor.subSlamWarning(this.moduleSlamWarningCallback);
        this.moduleMonitor.subLocalizationState(this.moduleLoalizationStateCallback);

        this.moduleMonitor.subLocalizationWarning(this.moduleLoalizationWarningCallback);
        this.moduleMonitor.readMsg();
        this.moduleControl = new DroneControl(
          this.moduleMonitor.mqttClient,
          this.selectedModule
        );
      }
    },
    hadleTakeoffChanged(height) {
      if (height) {
        this.droneParams.takeoffHeight = height;
      }
    },
    handleWaitingTimeChanged(waitingTime) {
      if (waitingTime) {
        this.droneParams.waitingTime = waitingTime;
      }
    },
    handleBatteryFailSafeChanged(batteryFailSafe) {
      if (batteryFailSafe) {
        this.droneParams.batteryFailSafe = batteryFailSafe;
      }
    },
    // handleConnectionLostChanged(connectionLost) {
    //   if (connectionLost) {
    //     this.droneParams.connectionLost = connectionLost;
    //   }
    // },
    handlePointCloudParam(type, value) {
      switch (type) {
        case "mode":
          if (value == "intensity") {
            this.pointCloudParams.mode = "intensity";
            this.cloudViewer.material2d.uniforms.colorMap.value = false;
            this.cloudViewer.material3d.uniforms.colorMap.value = false;
          } else if (value == "height") {
            this.pointCloudParams.mode = "height";
            this.cloudViewer.material2d.uniforms.colorMap.value = true;
            this.cloudViewer.material3d.uniforms.colorMap.value = true;
          }
          break;
        case "pointSize":
          this.pointCloudParams.pointSize = parseFloat(value);
          this.cloudViewer.material2d.uniforms.size.value = value;
          this.cloudViewer.material3d.uniforms.size.value = value;
          break;
        case "opacity":
          this.pointCloudParams.opacity = parseFloat(value);
          this.cloudViewer.material2d.uniforms.opacity.value = value;
          this.cloudViewer.material3d.uniforms.opacity.value = value;
          break;
        case "heightMin":
          this.pointCloudParams.height.min = parseFloat(value);
          this.cloudViewer.material2d.uniforms.minHeight.value = value;
          this.cloudViewer.material3d.uniforms.minHeight.value = value;
          break;
        case "heightMax":
          this.pointCloudParams.height.max = parseFloat(value);
          this.cloudViewer.material2d.uniforms.maxHeight.value = value;
          this.cloudViewer.material3d.uniforms.maxHeight.value = value;
          break;
        default:
          break;
      }
    },
    getSites() {
      if (!this.siteList) {
        this.api.getSites(this.user.id, this.getSitesCallback);
      } else {
        this.getSite();
      }
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      let setSelectedSite = siteList.find(
        (site) => site.id == this.$route.params.siteId
      );
      this.$store.dispatch("setSelectedSite", setSelectedSite);
      this.getSite();
    },
    getSite() {
      if (
        !this.selectedSite ||
        this.selectedSite.id != this.$route.params.siteId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedSite", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.dispatch("updateMap", map);
        this.cloudViewer.loadPCD(this.selectedSite.map.url);
      }
    },
    handleDeleteMission(seletedMission) {
      let missionList = this.missionList.filter(
        (mission) => mission.name == seletedMission.name
      );
      for (let i = 0; i < missionList.length; i++) {
        this.api.deleteInspectionMission(
          this.deleteInspectionMissionCallback,
          this.$route.params.siteId,
          this.$route.params.mapId,
          missionList[i].id
        );
      }
    },
    deleteInspectionMissionCallback() {
      this.getInspectionMissions();
    },
    getInspectionMissions() {
      this.missionList = [];
      this.api.getInspectionMissions(
        this.getInspectionMissionsCallback,
        this.$route.params.siteId,
        this.$route.params.mapId
      );
    },
    getInspectionMissionsCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let mission = new InspectionMission(result[i]);
          this.missionList.push(mission);
        }
      }
      this.filteredMissionList = this.missionList.filter(
        (mission, index, array) => {
          return array.findIndex((i) => i.name === mission.name) === index;
        }
      );
    },
    handleLoadingMission(mission) {
      this.clearWaypointAll();
      let droneNum = 0;
      for (let i = 0; i < this.missionList.length; i++) {
        if (this.missionList[i].name == mission.name) {
          this.api.getInspectionGroups(
            this.getInspectionGroupsCallback,
            droneNum,
            this.$route.params.siteId,
            this.$route.params.mapId,
            this.missionList[i].id
          );
          droneNum++;
        }
      }
    },
    getInspectionGroupsCallback(data, droneNum, missionId) {
      let result = data.data.result;
      if (result) {
        result.sort((a, b) => a.impgSeq - b.impgSeq);
        let group_num = 1;
        for (let i = 0; i < result.length; i++) {
          if (result[i].impgShapeType != 0) {
            let shape = new Shape(group_num);
            shape.impgId = result[i].id;
            this.groups[droneNum].push(shape);
            group_num++;
          }
        }
      }
      this.api.getInspectionShapes(
        this.getInspectionShapesCallback,
        droneNum,
        this.$route.params.siteId,
        this.$route.params.mapId,
        missionId
      );
    },
    getInspectionShapesCallback(data, droneNum, missionId) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].type != "Waypoints") {
            let shape = this.groups[droneNum].find(
              (shape) => shape.impgId == result[i].impgId
            );
            if (shape) {
              shape.shape = result[i].type;
              shape.shapeId = result[i].id;
              switch (shape.shape) {
                case "Line":
                  this.api.getInspectionLine(
                    this.getInspectionLineCallback,
                    shape
                  );
                  break;
                case "Circle":
                  this.api.getInspectionCircle(
                    this.getInspectionCircleCallback,
                    shape
                  );
                  break;
                case "Underside":
                  this.api.getInspectionUnderside(
                    this.getInspectionUndersideCallback,
                    shape
                  );
                  break;
                case "Rectangle":
                  this.api.getInspectionRectangle(
                    this.getInspectionRectangleCallback,
                    shape
                  );
                  break;
                case "Merged Rectangle":
                  this.api.getInspectionRectangle(
                    this.getInspectionRectangleCallback,
                    shape
                  );
                  break;
                case "Merged Circle":
                  this.api.getInspectionCircle(
                    this.getInspectionCircleCallback,
                    shape
                  );
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
      this.api.getInspectionWaypoints(
        this.getInspectionWaypointsCallback,
        droneNum,
        this.$route.params.siteId,
        this.$route.params.mapId,
        missionId
      );
    },
    getInspectionLineCallback(data, shape) {
      let result = data.data.result;
      shape.from_wall = result.commonProperty.fromWall;
      shape.interval = result.commonProperty.interval;
      shape.setHeighParams(
        result.commonProperty.heightInterval,
        result.commonProperty.lowerHeight,
        result.commonProperty.upperHeight
      );
      shape.setLineInfo(Boolean(result.lineAuto), result.direction);
    },
    getInspectionUndersideCallback(data, shape) {
      let result = data.data.result;
      shape.from_wall = result.commonProperty.fromWall;
      shape.interval = result.commonProperty.interval;
      shape.setHeighParams(
        result.commonProperty.heightInterval,
        result.commonProperty.lowerHeight,
        result.commonProperty.upperHeight
      );
      shape.setBottomInfo(
        Boolean(result.bottomAuto),
        result.direction ? "long" : "short"
      );
    },
    getInspectionCircleCallback(data, shape) {
      let result = data.data.result;
      shape.from_wall = result.commonProperty.fromWall;
      shape.interval = result.commonProperty.interval;
      let circleCoeffs = [
        {
          x: result.coeffsX,
          y: result.coeffsY,
          radius: result.coeffsRadius,
        },
      ];
      shape.setHeighParams(
        result.commonProperty.heightInterval,
        result.commonProperty.lowerHeight,
        result.commonProperty.upperHeight
      );
      shape.setCircleInfo(
        result.direction ? "outward" : "inward",
        result.startAngle,
        circleCoeffs
      );
    },
    getInspectionRectangleCallback(data, shape) {
      let result = data.data.result;
      let rectCoeffs = [
        {
          point1_x: result.coeffsP1X,
          point1_y: result.coeffsP1Y,
          point2_x: result.coeffsP2X,
          point2_y: result.coeffsP2Y,
          point3_x: result.coeffsP3X,
          point3_y: result.coeffsP3Y,
          point4_x: result.coeffsP4X,
          point4_y: result.coeffsP4Y,
          rot: result.coeffsRot,
        },
      ];
      shape.from_wall = result.commonProperty.fromWall;
      shape.interval = result.commonProperty.interval;
      shape.setHeighParams(
        result.commonProperty.heightInterval,
        result.commonProperty.lowerHeight,
        result.commonProperty.upperHeight
      );
      shape.setRectInfo(result.direction ? "outward" : "inward", rectCoeffs);
    },
    getInspectionWaypointsCallback(data, droneNum) {
      let result = data.data.result;
      this.droneData[droneNum].state.waypointNumber = 0;
      if (result) {
        result.sort((a, b) => a.no - b.no);
        for (let i = 0; i < result.length; i++) {
          let shape = this.groups[droneNum].find(
            (shape) => shape.impgId == result[i].impgId
          );
          let waypointShape;
          let group_num;
          if (shape) {
            waypointShape = shape.shape;
            group_num = shape.group_num;
          } else {
            waypointShape = "waypoint";
            group_num = 0;
          }

          let waypoint = new Waypoint(
            result[i].x,
            result[i].y,
            result[i].z,
            result[i].yaw,
            result[i].pitch,
            waypointShape.toLowerCase(),
            false,
            false, // result[i].complete
            group_num,
            null // cameraMission
          );
          waypoint.id = result[i].id;
          // if(waypoint.completed) this.droneData[droneNum].state.waypointNumber ++;
          this.waypoints[droneNum].push(waypoint);
        }
        this.api.getInspectionCameraMissions(
          this.getInspectionCameraMissionsCallback,
          droneNum,
          this.$route.params.siteId,
          this.$route.params.mapId,
          result[0].impId
        );
      }
    },
    getInspectionCameraMissionsCallback(data, droneNum) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let cameraMission = new CameraMission(result[i]);
          let waypoint = this.waypoints[droneNum].find(
            (waypoint) => waypoint.id == cameraMission.impWpsId
          );
          waypoint.mission = cameraMission;
        }
      }
      this.isMissionListModalVisible = false;
      this.cloudViewer.resetWaypoints(droneNum);
    },
    handleSaveMission(missionName) {
      let checked = this.missionList.find(
        (mission) => mission.name == missionName
      );
      if (checked) {
        this.$store.commit(
          "openAlert",
          "이미 같은 이름의 미션이 존재합니다. 다른 이름으로 저장해주세요."
        );
      } else {
        this.tempWaypoints = [[], [], [], [], []];
        this.tempCameraMissions = [[], [], [], [], []];
        for (let i = 0; i < 5; i++) {
          if (this.waypoints[i].length != 0) {
            let body = {
              facilityMapId: this.$route.params.mapId,
              name: missionName,
              createdDatetime: formatISO(new Date()),
            };
            this.api.postInspectionMissions(
              this.postInspectionMissionsCallback,
              this.$route.params.siteId,
              this.$route.params.mapId,
              body,
              i
            );
          }
        }
      }
    },
    postInspectionMissionsCallback(data, num) {
      let result = data.data.result;
      for (let i = 0; i < this.groups[num].length; i++) {
        let impgShapeType;
        switch (this.groups[num][i].shape) {
          case "Circle":
            impgShapeType = 1;
            break;
          case "Line":
            impgShapeType = 2;
            break;
          case "Rectangle":
            impgShapeType = 3;
            break;
          case "Underside":
            impgShapeType = 4;
            break;
          case "Merged Circle":
            impgShapeType = 5;
            break;
          case "Merged Rectangle":
            impgShapeType = 6;
            break;
          default:
            break;
        }
        let body = {
          impId: result.id,
          impgSeq: i + 1,
          impgShapeType: impgShapeType,
          createdDatetime: formatISO(new Date()),
        };
        this.api.postInspectionGroups(
          this.postInspectionGroupsCallback,
          this.$route.params.siteId,
          this.$route.params.mapId,
          body,
          this.groups[num][i],
          num
        );
      }
      let body = {
        impId: result.id,
        impgSeq: 0,
        impgShapeType: 0,
        createdDatetime: formatISO(new Date()),
      };
      this.api.postInspectionGroups(
        this.postInspectionGroupsCallback,
        this.$route.params.siteId,
        this.$route.params.mapId,
        body,
        null,
        num
      );
    },
    postInspectionGroupsCallback(data, shape, num) {
      let result = data.data.result;
      if (shape) {
        let body = {
          shape: {
            impId: result.impId,
            impgId: result.id,
            name: `${shape.shape} ${this.findShapeNumber(num, shape)}`,
            type: shape.shape,
            typeProperty: result.impgShapeType,
            createdDatetime: formatISO(new Date()),
          },
          commonProperty: {
            fromWall: shape.from_wall,
            interval: shape.interval,
            heightInterval: shape.height_interval,
            lowerHeight: shape.lower_height,
            upperHeight: shape.upper_height,
            createdDatetime: formatISO(new Date()),
          },
        };
        let additionalProperties;
        switch (shape.shape) {
          case "Circle":
            additionalProperties = {
              direction: shape.circle_direction == "outward" ? 1 : 0,
              rotate: shape.circle_rotate,
              startAngle: shape.circle_start_angle,
              coeffsX: shape.circle_coeffs[0].x,
              coeffsY: shape.circle_coeffs[0].y,
              coeffsRadius: shape.circle_coeffs[0].radius,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionCircle(body);
            break;
          case "Line":
            additionalProperties = {
              auto: shape.line_auto ? 1 : 0,
              direction: shape.line_direction,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionLine(body);
            break;
          case "Rectangle":
            additionalProperties = {
              direction: shape.rect_direction == "outward" ? 1 : 0,
              coeffsP1X: shape.rect_coeffs[0].point1_x,
              coeffsP1Y: shape.rect_coeffs[0].point1_y,
              coeffsP2X: shape.rect_coeffs[0].point2_x,
              coeffsP2Y: shape.rect_coeffs[0].point2_y,
              coeffsP3X: shape.rect_coeffs[0].point3_x,
              coeffsP3Y: shape.rect_coeffs[0].point3_y,
              coeffsP4X: shape.rect_coeffs[0].point4_x,
              coeffsP4Y: shape.rect_coeffs[0].point4_y,
              coeffsRot: shape.rect_coeffs[0].rot,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionRectanlge(body);
            break;
          case "Underside":
            additionalProperties = {
              auto: shape.bottom_auto ? 1 : 0,
              direction: shape.bottom_direction == "long" ? 1 : 0,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionUnderside(body);
            break;
          case "Merged Circle":
            additionalProperties = {
              direction: shape.circle_direction == "outward" ? 1 : 0,
              rotate: shape.circle_rotate,
              startAngle: shape.circle_start_angle,
              coeffsX: shape.circle_coeffs[0].x,
              coeffsY: shape.circle_coeffs[0].y,
              coeffsRadius: shape.circle_coeffs[0].radius,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionCircle(body);
            break;
          case "Merged Rectangle":
            additionalProperties = {
              direction: shape.rect_direction == "outward" ? 1 : 0,
              coeffsP1X: shape.rect_coeffs[0].point1_x,
              coeffsP1Y: shape.rect_coeffs[0].point1_y,
              coeffsP2X: shape.rect_coeffs[0].point2_x,
              coeffsP2Y: shape.rect_coeffs[0].point2_y,
              coeffsP3X: shape.rect_coeffs[0].point3_x,
              coeffsP3Y: shape.rect_coeffs[0].point3_y,
              coeffsP4X: shape.rect_coeffs[0].point4_x,
              coeffsP4Y: shape.rect_coeffs[0].point4_y,
              coeffsRot: shape.rect_coeffs[0].rot,
              createdDatetime: formatISO(new Date()),
            };
            body = { ...body, ...additionalProperties };
            this.api.postInspectionRectanlge(body);
            break;
          default:
            break;
        }
      }
      this.createTempWaypoint(result, num);
    },
    findShapeNumber(num, shape) {
      let index = this.groups[num].indexOf(shape);
      let count = 1;
      for (let i = 0; i <= index; i++) {
        if (this.groups[num][i].shape === shape) {
          count++;
        }
      }
      return count;
    },
    createTempWaypoint(result, num) {
      let waypoints = this.waypoints[num].filter(
        (waypoint) => waypoint.groupNum == result.impgSeq
      );
      for (let i = 0; i < waypoints.length; i++) {
        let index = this.waypoints[num].indexOf(waypoints[i]);
        let waypoint = {
          impId: result.impId,
          impgId: result.id,
          no: index + 1,
          x: waypoints[i].x,
          y: waypoints[i].y,
          z: waypoints[i].z,
          yaw: waypoints[i].yaw,
          pitch: waypoints[i].pitch,
          createdDatetime: result.createdDatetime,
        };
        this.tempWaypoints[num].push(waypoint);
        if (this.tempWaypoints[num].length == this.waypoints[num].length) {
          this.api.postInspectionWaypoints(
            this.postInspectionWaypointsCallback,
            this.$route.params.siteId,
            this.$route.params.mapId,
            result.impId,
            num,
            this.tempWaypoints[num]
          );
        }
      }
    },
    postInspectionWaypointsCallback(data, num) {
      let result = data.data.result;
      if (result) {
        result.sort((a, b) => a.no - b.no);
        for (let i = 0; i < result.length; i++) {
          if (this.waypoints[num][i].mission) {
            let cameraMission = {
              impId: result[i].impId,
              impWpsId: result[i].id,
              gimbalPitch: this.waypoints[num][i].mission.gimbalPitch,
              shouldCapture: this.waypoints[num][i].mission.shouldCapture,
              createdDatetime: result[i].createdDatetime,
            };
            this.tempCameraMissions[num].push(cameraMission);
          }
        }
      }
      if (this.tempCameraMissions[num].length != 0) {
        this.api.postInspectionCameraMissions(
          this.postInspectionCameraMissionsCallback,
          this.$route.params.siteId,
          this.$route.params.mapId,
          result[0].impId,
          this.tempCameraMissions[num]
        );
      } else {
        this.postInspectionCameraMissionsCallback();
      }
    },
    postInspectionCameraMissionsCallback() {
      this.getInspectionMissions();
      this.isMissionSaveModalVisible = false;
    },
    connectionCallback(num){
      if(this.connectionAlert){
        clearTimeout(this.connectionAlert);
        this.connectionAlert = null;
      }
      this.connectionAlert = setTimeout(() => {
        this.$store.commit(
          "openAlert",
          `모듈 #${num+1}의 통신이 끊겼습니다. RTL 모드로 전환됩니다.`
        );
      }, 30000);
    },
    rtlCallback(num){
      this.$store.commit(
        "openAlert",
        `모듈 #${num+1}의 배터리 잔량이 낮습니다. RTL 모드로 전환됩니다.`
      );
    },
    siriusStateCallback(data, num) {
      if (data.state == "REQUESTING") {
        this.droneControl[num].initialize(
          this.selectedDroneType.name,
          "inspection"
        );
        this.droneControl[num].ftpDownloadInfo(this.ftpDownloadInfo);
      } else if (data.state == "DATAMANAGE") {
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `Drone${num + 1}의 Map을 설정 중입니다.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "LOCALIZATIONCONFIG") {
        this.droneData[num].state.localization = false;
        if (num == this.currentDroneId) {
          if (this.importantMessageTimer) {
            clearTimeout(this.importantMessageTimer);
          }
          this.importantMessage = `3D 맵에서 높이의 범위를 설정하고, 2D 맵에서 드래그하여 드론의 초기 영역을 지정해주세요.`;
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
          this.isPlanModalVisible = false;
          this.isMissionListModalVisible = false;
          this.isMissionSaveModalVisible = false;
          this.localization_height.max = this.cloudViewer.heightLimitMax.toFixed(0);
          this.localization_height.min = this.cloudViewer.heightLimitMin.toFixed(0);
          this.localization_height.limitMax = this.cloudViewer.heightLimitMax.toFixed(0);
          this.localization_height.limitMin = this.cloudViewer.heightLimitMin.toFixed(0);
          this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
          this.cloudViewer.setHeightRange(this.localization_height.min, this.localization_height.max);
          this.cloudViewer.setHeightInfoVisibe(true);
        }
      } else if (data.state == "INITIALIZATION") {
        this.droneData[num].state.localization = true;
        this.cloudViewer.rectClear();
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `Drone${num + 1}의 프로그램이 실행 중입니다.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
        this.cloudViewer.setHeightInfoVisibe(false);
      } else if (data.state == "STANBY") {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `Drone${
          num + 1
        }의 프로그램 실행이 완료되었습니다. 작동 상태를 최종 점검하세요.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "FAILSAFE") {
        if (this.droneControl[num]) {
          this.droneControl[num].stop();
        }
      }
    },

    siriusErrorCallback(data, num) {
      this.statusText.push({
        severity: 0,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataStateCallback(data,num){
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataErrorCallback(data,num){
      this.statusText.push({
        severity: 3,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    slamWarningCallback(data,num){
      this.statusText.push({
        severity: 4,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    localizationStateCallback(data, num){
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    localizationWarningCallback(data, num){
      this.statusText.push({
        severity: 4,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    waypointErrorCallback(data, num) {
      this.statusText.push({
        severity: 0,
        text: `[#${num + 1}] ${data.message}`,
      });
    },
    moduleConnectionCallback(){
      if(this.connectionAlert){
        clearTimeout(this.connectionAlert);
        this.connectionAlert = null;
      }
      this.connectionAlert = setTimeout(() => {
        this.$store.commit(
          "openAlert",
          `모듈의 통신이 끊겼습니다. 통신 상태를 확인해주세요.`
        );
      }, 30000);
    },
    moduelRtlCallback(){
      this.$store.commit(
        "openAlert",
        `배터리 잔량이 낮습니다. 배터리의 상태를 확인해주세요.`
      );
    },
    moduleStateCallback(data) {
      if (data.state == "REQUESTING") {
        this.moduleControl.initialize(
          this.selectedDroneType.name,
          "inspection"
        );
        this.moduleControl.ftpDownloadInfo(this.ftpDownloadInfo);
      } else if (data.state == "DATAMANAGE") {
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `Sirius의 Map을 설정 중입니다.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "LOCALIZATIONCONFIG") {
        this.moduleData.state.localization = false;
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `2D 맵에서 드래그하여 Sirius의 초기 영역을 지정해주세요.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 10000);
        this.isPlanModalVisible = false;
        this.isMissionListModalVisible = false;
        this.isMissionSaveModalVisible = false;
        this.localization_height.max = this.cloudViewer.heightLimitMax.toFixed(0);
        this.localization_height.min = this.cloudViewer.heightLimitMin.toFixed(0);
        this.localization_height.limitMax = this.cloudViewer.heightLimitMax.toFixed(0);
        this.localization_height.limitMin = this.cloudViewer.heightLimitMin.toFixed(0);
        this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
        this.cloudViewer.setHeightRange(this.localization_height.min, this.localization_height.max);
        this.cloudViewer.setHeightInfoVisibe(true);
      } else if (data.state == "INITIALIZATION") {
        this.moduleData.state.localization = true;
        this.cloudViewer.rectClear();
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `Sirius의 프로그램이 실행 중입니다.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "STANBY") {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `Sirius의 프로그램 실행이 완료되었습니다. 작동 상태를 최종 점검하세요.`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      }
    },
    localPoseCallback(data, num) {
      this.droneData[num].pose.x = data.position_x;
      this.droneData[num].pose.y = data.position_y;
      this.droneData[num].pose.z = data.position_z;
      this.droneData[num].pose.roll =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_x +
              data.orientation_y * data.orientation_z),
          1 -
            2 *
              (data.orientation_x * data.orientation_x +
                data.orientation_y * data.orientation_y)
        ) *
          180) /
        Math.PI;
      this.droneData[num].pose.pitch =
        (Math.asin(
          Math.max(
            -1.0,
            Math.min(
              1.0,
              2 *
                (data.orientation_w * data.orientation_y -
                  data.orientation_z * data.orientation_x)
            )
          )
        ) *
          180) /
        Math.PI;
      this.droneData[num].pose.yaw =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_z +
              data.orientation_x * data.orientation_y),
          1 -
            2 *
              (data.orientation_y * data.orientation_y +
                data.orientation_z * data.orientation_z)
        ) *
          180) /
        Math.PI;

      if (this.cloudViewer.droneModel2D[num]) {
        this.cloudViewer.droneModel2D[num].position.set(
          data.position_x,
          0,
          -data.position_y
        );
        this.cloudViewer.droneModel2D[num].rotation.order = "YXZ";
        this.cloudViewer.droneModel2D[num].rotation.set(
          0,
          (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
          0
        );
      }
      if (this.cloudViewer.droneModel3D[num]) {
        this.cloudViewer.droneModel3D[num].position.set(
          data.position_x,
          data.position_z,
          -data.position_y
        );
        this.cloudViewer.droneModel3D[num].rotation.order = "YXZ";
        this.cloudViewer.droneModel3D[num].rotation.set(
          (-this.droneData[num].pose.pitch * Math.PI) / 180,
          (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
          0
        );
      }
    },
    stateCallback(data, num) {
      this.droneData[num].fcu = Boolean(data.connection);
      this.droneData[num].flightMode = data.mode;
      this.droneData[num].state.arming = Boolean(data.arming);
      if (this.droneData[num].state.arming) {
        if (!this.droneData[num].timeCheck) {
          this.droneData[num].armingTime = new Date();
          this.droneData[num].timeCheck = true;
        } else {
          let nowTime = new Date();
          let differenceInMilliseconds =
            nowTime - this.droneData[num].armingTime;
          let hours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
          let minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
          let seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
          this.droneData[num].flightTime.h = String(hours).padStart(2, "0");
          this.droneData[num].flightTime.m = String(minutes).padStart(2, "0");
          this.droneData[num].flightTime.s = String(seconds).padStart(2, "0");
        }
      } else {
        this.droneData[num].timeCheck = false;
        if (this.droneData[num].state.rtl)
          this.droneData[num].state.rtl = false;
        if (this.droneData[num].state.rth)
          this.droneData[num].state.rth = false;
        if (this.droneData[num].state.mission)
          this.droneData[num].state.mission = false;
      }
      if (data.system_status == 3) {
        this.droneData[num].state.takeOff = false;
      } else if (data.system_status == 4) {
        this.droneData[num].state.takeOff = true;
      }
    },
    batteryCallback(data, num) {
      if (data.percentage) {
        this.droneData[num].battery = data.percentage.toFixed(0);
      }
    },
    statusTextCallback(data, num) {
      if (
        data.severity == 0 ||
        data.severity == 1 ||
        data.severity == 2 ||
        data.severity == 3
      ) {
        // this.api.postDroneDataLog(this.droneMonitor[num].clientId, data, formatISO(new Date()));
      }
      this.statusText.push({
        severity: data.severity,
        text: `[#${num + 1}] ${data.text}`,
      });
    },
    sensorStateCallback(data, num) {
      this.droneData[num].sensorState.lidar = Boolean(data.lidar_connection);
      this.droneData[num].sensorState.imu = Boolean(data.imu_connection);
      this.droneData[num].sensorState.encoder = Boolean(
        data.encoder_connection
      );
    },
    groundSpeedCallback(data, num) {
      this.droneData[num].speed = data.ground_speed.toFixed(2);
    },
    gpsModeCallback(data, num) {
      this.droneData[num].gpsMode = Boolean(data.gps_mode);
    },
    waypointCallback(data, num) {
      if (this.droneData[num].state.mission) {
        if (this.waypoints[num][data.waypoint_number - 1]) {
          if (
            this.droneData[num].state.waypointNumber != data.waypoint_number
          ) {
            this.droneData[num].state.waypointNumber = data.waypoint_number;
            this.handleWaypointNumber(
              num,
              this.droneData[num].state.waypointNumber
            );
          }
          this.droneControl[num].setWaypoint(
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].x,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].y,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].z,
            this.waypoints[num][
              this.droneData[num].state.waypointNumber - 1
            ].getYawDegree(),
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .pitch,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .fitType
          );
          this.droneControl[num].mission(
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .mission.gimbalPitch,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .mission.shouldCapture
          );
        } else {
          this.droneControl[num].stop();
          this.droneData[num].state.mission = false;
          this.handleWaypointNumber(num, 0);
          this.$store.commit(
            "openAlert",
            "미션이 종료되었습니다."
          );
        }
        this.cloudViewer.deleteTempWaypoint(num);
      }
      // else if(this.droneData[num].state.rtl){
      //   this.droneControl[num].land();
      // }
      // else if(this.droneData[num].state.rth){
      //   this.droneControl[num].land();
      // }
    },
    tempWaypointCallback(data, num) {
      // if(this.droneData[num].state.mission){
      // }
      this.cloudViewer.deleteTempWaypoint(num);
      let paths = data.paths;
      this.cloudViewer.drawTempWaypoint(paths, num);
    },
    slamStateCallback(data, num) {
      this.droneData[num].slamState = Boolean(data.slam_state);
    },
    modulePoseCallback(data) {
      let roll = Math.atan2(
        2 *
          (data.slam_orientation_w * data.slam_orientation_x +
            data.slam_orientation_y * data.slam_orientation_z),
        1 -
          2 *
            (data.slam_orientation_x * data.slam_orientation_x +
              data.slam_orientation_y * data.slam_orientation_y)
      );
      let pitch = Math.asin(
        Math.max(
          -1.0,
          Math.min(
            1.0,
            2 *
              (data.slam_orientation_w * data.slam_orientation_y -
                data.slam_orientation_z * data.slam_orientation_x)
          )
        )
      );
      let yaw = Math.atan2(
        2 *
          (data.slam_orientation_w * data.slam_orientation_z +
            data.slam_orientation_x * data.slam_orientation_y),
        1 -
          2 *
            (data.slam_orientation_y * data.slam_orientation_y +
              data.slam_orientation_z * data.slam_orientation_z)
      );
      data.slam_roll = roll;
      data.slam_pitch = pitch;
      data.slam_yaw = yaw;
      for (let i = 0; i < 5; i++) {
        if (
          this.droneControl[i] &&
          this.droneData[i].state.djiConnection !== undefined
        ) {
          if (this.droneData[i].state.djiConnection) {
            this.droneControl[i].modulePose(JSON.stringify(data));
          }
        }
      }
    },
    moduleStateErrorCallback(data){
      this.statusText.push({
        severity: 0,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleDataCallback(data){
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleDataErrorCallback(data){
      this.statusText.push({
        severity: 3,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleSlamWarningCallback(data){
      this.statusText.push({
        severity: 4,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleLoalizationStateCallback(data){
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleLoalizationWarningCallback(data){
      this.statusText.push({
        severity: 4,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    storageCallback(data, num) {
      this.droneData[num].storage = (
        (data.used_storage / data.total_storage) *
        100
      ).toFixed(1);
    },
    djiConnectionCallback(data, num) {
      if (data.dji_connection) {
        this.droneData[num].state.djiConnection = true;
      }
    },
    djiModulePoseStopCallback(data, num) {
      if (data.dji_initpose) {
        this.droneData[num].state.djiConnection = false;
      }
    },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    getDroneTypes() {
      this.api.getDronesType(this.getDronesTypeCallback);
    },
    getDronesTypeCallback(data) {
      let result = data.data.result;
      this.droneTypeList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneTypeList.push(new DroneType(result[i]));
      }
    },
    ftpCredentialsCallback(data) {
      this.ftpDownloadInfo = JSON.stringify(data.data.result);
    },
    cleanUp() {
      if (this.cloudViewer) {
        this.cloudViewer.dispose();
        this.cloudViewer = null;
      }
      this.waypoints = [[], [], [], [], []];
      this.groups = [[], [], [], [], []];
      if (this.webSoketHandler) {
        if (this.parsingCompletedListener)
          this.webSoketHandler.off(
            "parsingCompleted",
            this.parsingCompletedListener
          );
        if (this.setCollidedWPListener)
          this.webSoketHandler.off("setCollidedWP", this.setCollidedWPListener);
        this.webSoketHandler.programClose();
      }
      for (let i = 0; i < 5; i++) {
        if (this.droneMonitor[i]) {
          this.api.putDrone(this.droneMonitor[i].module.id,"disConnecting");
          this.droneMonitor[i].closeMqtt();
          this.droneMonitor[i] = null;
        }
        if (this.droneControl[i]) this.droneControl[i] = null;
      }
      if (this.moduleMonitor) {
        this.moduleMonitor.closeMqtt();
        this.moduleMonitor = null;
        this.api.putDrone(this.moduleMonitor.module.id,"disConnecting");
      }
      if (this.moduleControl) this.moduleControl = null;
      if(this.connectionAlert){
        clearTimeout(this.connectionAlert);
        this.connectionAlert = null;
      }
      if(this.importantMessage){
        clearTimeout(this.importantMessage);
        this.importantMessage = null;
      }
    },
    handleLocalizationHeight(event, type) {
      let value = parseFloat(parseFloat(event.target.value).toFixed(2));
      if(event.target.value){
        switch (type) {
        case 'min':
          if(value > this.localization_height.limitMax){
            this.$store.commit(
              "openAlert",
              `${this.localization_height.limitMax}보다 큰 값은 입력하실 수 없습니다.`
            );
            this.localization_height.min = this.localization_height.limitMax;
            this.localization_height.max = this.localization_height.limitMax;
          }
          else if(value < this.localization_height.limitMin){
            this.localization_height.min = this.localization_height.limitMin;
            this.$store.commit(
              "openAlert",
              `${this.localization_height.limitMin}보다 작은 값은 입력하실 수 없습니다.`
            );
          }else{
            if (value > this.localization_height.max) {
            this.localization_height.min = value;
            this.localization_height.max = value;
            }
            else this.localization_height.min = value;
          }
          break;
        case 'max':
          if(value > this.localization_height.limitMax){
            this.$store.commit(
              "openAlert",
              `${this.localization_height.limitMax}보다 큰 값은 입력하실 수 없습니다.`
            );
            this.localization_height.max = this.localization_height.limitMax;
          }
          else if(value < this.localization_height.limitMin){
            this.localization_height.max = this.localization_height.limitMin;
            this.localization_height.min = this.localization_height.limitMin;
            this.$store.commit(
              "openAlert",
              `${this.localization_height.limitMin}보다 작은 값은 입력하실 수 없습니다.`
            );
          }else{
            if (value < this.localization_height.min) {
            this.localization_height.min = value;
            this.localization_height.max = value;
            }
            else this.localization_height.max = value;
          }
          break;
        default:
          break;
        }
        this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
        this.cloudViewer.setHeightRange(this.localization_height.min, this.localization_height.max);
      }
      event.target.value = null;
    },
  },
  created() {
    this.$store.dispatch("checkDeviceType");
  },
  mounted() {
    let ref = this.isMobile
      ? this.$refs.cloud_viewer_mobile_2d
      : this.$refs.cloud_viewer_2d;
    this.cloudViewer = markRaw(
      new PCReportViewer(
        ref,
        this.$refs.cloud_viewer_3d,
        this.pointCloudParams,
        this.$refs.mainLoading,
        this.isMobile,
        this.waypoints
      )
    );

    this.webSoketHandler = markRaw(
      new WebSoketHandler(
        this.apiIp,
        this.apiPort,
        this.user,
        this.waypoints,
        this.groups
      )
    );
    this.parsingCompletedListener = () => {
      this.messageParsingFinished();
    };
    this.webSoketHandler.on("parsingCompleted", this.parsingCompletedListener);
    this.setCollidedWPListener = () => {
      this.setCollidedWaypoints();
    };
    this.webSoketHandler.on("setCollidedWP", this.setCollidedWPListener);
    this.webSoketHandler.on("waypointNull", () => {
      this.$store.commit(
        "openAlert",
        "자동경로생성에 실패한 영역이 있습니다. 영역을 정확하게 설정해주세요."
      );
    });
    this.webSoketHandler.on("noCollision", () => {
      this.$store.commit(
        "openAlert",
        "충돌되는 웨이포인트가 없습니다."
      );
    });
    this.groupHandler = new GroupHandler(this.waypoints, this.groups);
    this.api.putUser({"taskStatus" : "inspecting"}, this.user.id);
    this.getSites();
    this.api.startFittingProgram(
      this.$route.params.siteId,
      this.$route.params.mapId,
      this.apiPort,
      this.user.id
    );
    this.getDroneTypes();
    this.getDrones();
    this.getInspectionMissions();
    this.api.ftpCredentials(
      this.ftpCredentialsCallback,
      this.$route.params.siteId,
      this.$route.params.mapId
    );
    window.addEventListener("beforeunload",()=>{
      for (let i = 0; i < 5; i++) {
        if (this.droneMonitor[i]) this.api.putDrone(this.droneMonitor[i].module.id,"disConnecting");
      }
    })
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.main {
  display: flex;
  align-items: flex-start;
}

.cloud_viewer_3d {
  position: relative;
  width: 50%;
  height: 100%;
}

.cloud_viewer_2d {
  position: relative;
  width: 50%;
  height: 100%;
}

.inspection_view_label {
  position: fixed;
  bottom: 10px;
  right: 20px;
  height: 30px;
  width: auto;
  padding: 5px;
  @include flexbox;
  font-size: 1.6rem;
}

.inspection_drone_selection_container {
  position: fixed;
  top: 80px;
  left: calc(100% / 2 + 10px);
  height: 30px;
  width: 110px;
  background-color: rgb(23, 23, 23);
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  cursor: pointer;
}

.inspection_selected_drone {
  margin-left: 10px;
  color: $main_white;
  font-size: 1.6rem;
}

.isnpection_select_drone_image {
  width: 30px;
  height: 30px;
  margin-left: auto;
  background-image: url("@/../public/images/chevronDown.svg");
  background-size: 20px, 20px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: transparent; */
}

.isnpection_select_drone_image:hover {
  background-image: url("@/../public/images/chevronDown_hover.svg");
}
.inspection_drone_localization_apply_button {
  @include flexbox;
  position: fixed;
  top: 80px;
  left: calc(100% / 2 + 130px);
  height: 30px;
  width: 80px;
  background-color: rgb(23, 23, 23);
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  margin-left: 10px;
  color: $main_white;
  font-size: 1.6rem;
}
.inspection_drone_localization_apply_button:hover {
  background: $main_color;
  color: black;
}
.inspection_select_drone_modal {
  position: fixed;
  top: 120px;
  left: calc(100% / 2 + 10px);
  height: 150px;
  width: 110px;
  background-color: rgb(23, 23, 23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}

.inspection_drone_item {
  height: 30px;
  width: 90px;
  border-bottom: 1px solid rgba(1, 250, 254, 0.5);
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 1.6rem;
  color: $main_white;
}

.inspection_drone_item:hover {
  color: rgba(1, 250, 254, 0.5);
}

.last {
  border-bottom: 0px solid rgba(1, 250, 254, 0.5);
}
</style>
