<template>
  <div id="form_content_wrap">
    <form class="form_wrap">
      <div class="content_area custom_scrollbar">
        <div class="profile">
          <div
            v-if="editMode === 'myInfo'"
            class="profile_image_box"
            :class="{
              no_image:
                (!uploadedImageUrl && !user.profileImageUrl) || isDeleted,
            }"
            :style="[
              (uploadedImageUrl && !isDeleted) ||
              (user.profileImageUrl && !isDeleted)
                ? {
                    backgroundImage: `url(${
                      uploadedImageUrl || user.profileImageUrl
                    })`,
                  }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
          <div
            v-else
            class="profile_image_box"
            :class="{
              no_image:
                (!uploadedImageUrl && !selectedUserInfo.profileImageUrl) ||
                isDeleted,
            }"
            :style="[
              (uploadedImageUrl && !isDeleted) ||
              (selectedUserInfo.profileImageUrl && !isDeleted)
                ? {
                    backgroundImage: `url(${
                      uploadedImageUrl || selectedUserInfo.profileImageUrl
                    })`,
                  }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
        </div>
        <!-- 업로드한 이미지가 있거나, 이미 프로필 이미지가 존재할 때에만 보여지도록 -->
        <div
          class="delete_area"
          v-if="uploadedImageUrl || selectedUserInfo.profileImageUrl"
        >
          <button type="button" @click="removeProfileImage">
            프로필 이미지 삭제
          </button>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="">이름</label>
            <div class="input_container focus">
              <input
                class=""
                ref="name"
                type="text"
                placeholder="새로운 이름을 입력하세요."
                v-model="defaultUserFullName"
                @input="inputCheck($event, 10)"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="">권한</label>

            <DefaultSelectBox
              v-if="user.role === 'admin'"
              :className="`focus full`"
              :isOptionOpen="isOptionOpen"
              :defaultName="selectedUserInfo.role"
              :selectedName="clickedOptionValue"
              @update:isOptionOpen="isOptionOpen = $event"
              @controlOptionToggle="controlOptionToggle"
            >
              <ul>
                <li
                  v-for="(item, index) in adminArray"
                  :key="item + index"
                  @click="handleClickOption(item)"
                  :class="item === this.clickedOptionValue ? 'active' : ''"
                >
                  <p>{{ item }}</p>
                </li>
              </ul>
            </DefaultSelectBox>

            <div class="input_container" v-else>
              <input
                class=""
                ref="userId"
                type="text"
                :placeholder="changeRoleToKorean(clickedOptionValue)"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="">부서</label>
            <div class="input_container focus">
              <input
                class=""
                ref="field"
                type="text"
                placeholder="새로운 부서를 입력하세요."
                autocomplete="off"
                v-model="defaultUserFieldValue"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="">직급</label>
            <div class="input_container focus">
              <input
                class=""
                ref="position"
                type="text"
                placeholder="새로운 직급을 입력하세요."
                autocomplete="off"
                v-model="defaultUserPositionValue"
              />
            </div>
          </div>
        </div>
        <p class="gray_text">
          * 부서 및 직급은 정확히 입력해 주세요. 예: '마케팅팀'과 '마케팅'은
          다른 항목으로 처리될 수 있습니다.
        </p>
        <label class="">아이디</label>
        <div class="input_container">
          <input
            class=""
            ref="userId"
            type="text"
            v-model="defaultUserIdValue"
            :placeholder="defaultUserIdValue"
            @input="inputCheck($event, 10)"
          />
        </div>
        <label class="">새로운 비밀번호</label>
        <div class="input_container focus">
          <input
            class=""
            type="password"
            ref="password"
            placeholder="새로운 비밀번호를 입력하세요."
            autocomplete="off"
          />
          <button
            class="eye_button"
            :class="isPasswordShow ? 'on' : ''"
            type="button"
            @click="showPassword($event, 'password')"
          ></button>
        </div>
        <label class="">연락처</label>
        <div class="input_container focus">
          <input
            class=""
            ref="contact"
            type="text"
            placeholder="숫자만 입력해 주세요."
            autocomplete="off"
            v-model="defaultUserContactValue"
            @input="onInputChange"
          />
        </div>
        <label>담당 시설물</label>
        <div class="facility_box">
          <template
            v-if="
              selectedUserInfo.facility && selectedUserInfo.facility.length > 0
            "
          >
            <span
              v-for="(facility, index) in selectedUserInfo.facility"
              :key="facility + index"
              class="state_badge gray white_text"
            >
              {{ facility.facilityName }}
            </span>
          </template>
          <template v-else>
            <p>담당 시설물이 없습니다.</p>
          </template>
        </div>
        <!-- 선택된 아이디와 로그인된 사용자의 아이디가 일치하지 않을때 보여짐 (본인의 경우 삭제 불가능) -->
        <div class="delete_area" v-if="user.id !== selectedUserInfo.id">
          <button type="button" class="red_text" @click="removeUser">
            사용자 삭제하기
          </button>
        </div>
      </div>
      <div class="button_box">
        <button @click="submitEdit" class="default_bg_button" type="button">
          수정
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          취소
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { changeRoleToEnglish } from "@/shared/util/changeRoleToEnglish";
import { changeRoleToKorean } from "@/shared/util/changeRoleToKorean";
import { changeSiblingInputType } from "@/shared/util/changeSiblingInputType";
// import { checkAuthorizeLimit } from "@/shared/util/checkAuthorizeLimit";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { filterNonNumeric } from "@/shared/util/filterNonNumeric";
import { getFacilityNames } from "@/shared/util/getFacilityNames";
import { mapState } from "vuex";
import CryptoJS from "crypto-js";
// import { filterValidValues } from "@/shared/util/filterValidValues";

export default {
  name: "UserEditFormContent",
  emits: ["handleRemoveUser", "handleEditUser", "handleRemoveProfileImage"],
  computed: {
    ...mapState(["user"]),
  },
  components: { DefaultSelectBox },
  props: {
    uploadedImageUrl: {
      type: [String, null],
      required: false,
    },
    isDeleted: {
      type: Boolean,
      required: false,
    },
    selectedUserInfo: {
      type: Object,
      required: false,
    },
    userListArray: {
      type: Array,
      required: false,
    },
    editMode: {
      type: String,
      required: false,
      default: "myInfo",
    },
  },
  data() {
    return {
      defaultUserFullName: this.selectedUserInfo.fullName,
      defaultUserIdValue:
        this.selectedUserInfo.userLoginId || this.selectedUserInfo.name,
      defaultUserContactValue: this.selectedUserInfo.contact,
      defaultUserFieldValue: this.selectedUserInfo.affiliation.departmentName,
      defaultUserPositionValue: this.selectedUserInfo.affiliation.rankName,
      clickedOptionValue: changeRoleToKorean(this.selectedUserInfo.role),
      isPasswordShow: false,
      adminArray: ["일반", "매니저"],
      isOptionOpen: false,
      imageFileInfo: null,
    };
  },
  methods: {
    getFacilityNames,
    changeRoleToKorean,
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isOptionOpen = !this.isOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickOption(value) {
      this.isOptionOpen = false;
      this.clickedOptionValue = value;
    },
    /**
     * admin이 아니라면 변경 불가 알럿 기능
     */
    isNotAllowed() {
      if (this.selectedUserInfo.role !== "admin") {
        return this.$store.commit(
          "openAlert",
          `변경할 수 없는 항목입니다. <br>변경을 원하시면 관리자에게 문의해 주세요.`
        );
      }
    },
    inputCheck(event, limit) {
      checkInputLimit(event, limit, this.$store);
    },
    showPassword(event, target) {
      if (target === "password") {
        this.isPasswordShow = !this.isPasswordShow;
      } else {
        this.isPasswordConfirmShow = !this.isPasswordConfirmShow;
      }
      changeSiblingInputType(event);
    },
    /**
     * 파일 업로드
     */
    uploadFile(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        const basename = file.name.replace(/\.[^/.]+$/, "");

        // 수정된 특수 문자 정규 표현식
        let regExp = /[`~!@#$%^&*|\\'";:/?()]/gi;

        // 특수 문자가 포함되어 있는지 체크
        if (regExp.test(basename)) {
          return this.$store.commit(
            "openAlert",
            `이미지 파일명에 특수 문자는 제거하고 업로드해 주세요.`
          );
        }

        this.imageFileInfo = file;
        this.$emit("handleUploadFile", file);
      }
    },
    /**
     * 사용자 정보 수정 기능
     */
    submitEdit() {
      let userName = this.$refs.name.value;
      let userRole =
        this.clickedOptionValue === null
          ? null
          : changeRoleToEnglish(this.clickedOptionValue);
      let userId = this.$refs.userId.value;
      let userPw = this.$refs.password.value;
      let userContact = this.$refs.contact.value;
      let userField = this.$refs.field.value;
      let userPosition = this.$refs.position.value;

      if (!userName || !userId || !userField || !userPosition) {
        return this.$store.commit(
          "openAlert",
          `이름, 부서, 직급, 아이디 값은 비워져있을 수 없습니다. <br> 값을 입력해 주시거나, 취소를 눌러주세요.`
        );
      }

      const userInfo = {
        adminId: this.user.id,
        userLoginId: userId ? userId : null,
        userPassword: userPw ? CryptoJS.SHA256(userPw).toString() : null,
        fullName: userName ? userName : null,
        role: userRole ? userRole : null,
        contact: userContact ? userContact : null,
        affiliation: {
          id:
            this.editMode === "myInfo"
              ? this.user.affiliation.id
              : this.selectedUserInfo.affiliation.id,
          departmentName: userField ? userField : null,
          rankName: userPosition ? userPosition : null,
        },
      };

      // 값이 없는 키 값을 제외한 새로운 객체 생성
      // const newUserInfo = filterValidValues(userInfo);

      // 원래 권한과 지금 선택한 권한의 값이 다를 때
      // if (this.selectedUserInfo.role !== userRole) {
      //   // 매니저와 일반 사용자의 갯수를 체크해서 초과되었을 경우 막아준다.
      //   if (!checkAuthorizeLimit(this.userListArray, userRole, this.$store)) {
      //     return;
      //   }
      // }

      this.$emit("handleEditUser", userInfo);
    },
    /**
     * 사용자 정보 삭제 기능
     */
    removeUser() {
      const isConfirmed = confirm(
        `${this.selectedUserInfo.fullName}님을 삭제하시겠습니까? 한번 삭제되면 되돌릴 수 없습니다.`
      );

      if (isConfirmed) {
        this.$emit("handleRemoveUser", this.selectedUserInfo);
      }
    },
    /**
     * 프로필 이미지 삭제
     */
    removeProfileImage() {
      this.$emit("handleRemoveProfileImage");
    },
    onInputChange(event) {
      // 숫자가 아닌 입력값을 제외시킨다.
      this.defaultUserContactValue = filterNonNumeric(event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/component/inputs";

label {
  position: relative;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 12px;
  display: inline-block;
  margin-top: 18px;

  & ~ label {
    margin-top: 18px;
  }
}

.delete_area {
  @include flexbox(center, flex-end, row);
  margin: 12px 0;

  button {
    text-decoration: underline;
    color: $gray_9fa6b2;

    &.red_text {
      color: $red_ff7070;
    }
  }
}

p.gray_text {
  font-size: 1.4rem;
  margin-top: 12px;
}

.facility_box {
  @include flexbox(center, flex-start, row);
  gap: 6px;
  flex-wrap: wrap;
  font-size: 1.4rem;

  span {
    cursor: default;
  }

  p {
    font-size: 1.4rem;
    color: $gray_a1a1a1;
  }
}
</style>
