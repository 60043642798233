<template>
  <div class="main_fixed main korean">
    <MainHeaderVue :navigator="navigator" />
    <ReportUploadModalVue
      ref="uploadModal"
      :uploadState="uploadState"
      @closeUploadModal="handleUploadModal"
      :volume="volume"
      @clickUpload="checkStorage"
      @uploadAlbum="uploadAlbum"
    />
    <div
      class="cloud_viewer"
      ref="cloud_viewer"
      :class="{ detail: reportDetails }"
    ></div>
    <ReportLeftLayoutVue :reportDetails="reportDetails">
      <ReportMapListVue
        ref="mapList"
        @selectMap="selectMap"
        @deleteMap="deleteMap"
        @expandAlbumMenu="expandAlbumMenu"
        :filteredMapList="filteredMapList"
      />
      <ReportAlbumListVue
        ref="albumList"
        @openUploadModal="handleUploadModal"
        @selectAlbum="selectAlbum"
        @deleteAlbum="deleteAlbum"
        @downloadAlbum="downloadAlbum"
        :uploadState="uploadState"
        @unSelectAlbum="unSelectAlbum"
        @expandMapMenu="expandMapMenu"
        :albumList="albumList"
        :selectedAlbumList="selectedAlbumList"
        @clickAnalysis="postAnalysis"
      />
      <ReportHardVolumeVue :volume="volume"></ReportHardVolumeVue>
    </ReportLeftLayoutVue>
    <ReportInfoVue
      v-if="reportDetails"
      :selectedAlbumList="selectedAlbumList"
      :selectedPhoto="selectedPhoto"
      :selectedCamera="selectedCamera"
      :photoList="photoList"
    />
    <ReportBottomLayoutVue
      ref="reportBottomLayout"
      v-if="photoList.length != 0 && selectedAlbumList.length != 0"
      @changeReportVue="changeReportVue"
      @changeVisiblePhotoList="handleVisiblePhotoList"
      @clickPhoto="clickPhoto"
      @deletePhoto="deletePhoto"
      :reportDetails="reportDetails"
      :selectedAlbumList="selectedAlbumList"
      :photoList="photoList"
      :selectedPhoto="selectedPhoto"
      :visiblePhotoList="visiblePhotoList"
      :segmentationList="segmentationList"
    />
    <ReportDetailsVue
      ref="report_detail"
      @openReportEditor="handleOpenReportEditor"
      @changeImageState="handleImageState"
      @clickPhoto="clickPhoto"
      :reportDetails="reportDetails"
      :imageState="imageState"
      :photoList="photoList"
      :selectedPhoto="selectedPhoto"
    >
      <ReportDetailsInfoVue
        :selectedPhoto="selectedPhoto"
        :selectedCamera="selectedCamera"
      />
      <ReportDetailsCrackVue
        :infos="infos"
        :selectedPhoto="selectedPhoto"
        :selectedCamera="selectedCamera"
      />
    </ReportDetailsVue>
  </div>

  <ReportEditorVue
    ref="report_editor"
    v-if="selectedAlbumList.length != 0 && reportEditor"
    :drawState="drawState"
    :imageState="imageState"
    :photoList="photoList"
    :selectedPhoto="selectedPhoto"
    :selectedSegmentation="selectedSegmentation"
    @changeImageState="handleImageState"
    @clickPhoto="clickPhoto"
  >
    <template v-slot:editor-info>
      <ReportEditorInfoVue
        :selectedPhoto="selectedPhoto"
        :selectedCamera="selectedCamera"
      />
    </template>
    <template v-slot:editor-crack>
      <ReportEditorCrackVue
        :infos="infos"
        :drawState="drawState"
        :selectedCamera="selectedCamera"
        :selectedSegmentation="selectedSegmentation"
        @closeReportEditor="reportEditor = false"
        @changeDrawState="handleDrawState"
        @changeDrawModalState="handleDrawModalState"
        @deleteCrackAll="deleteCrackAll"
        @drawCrackAll="drawCrackAll"
        @deleteCrack="deleteCrack"
        @drawCrack="drawCrack"
        @analysisSave="analysisSave"
        @openClassModal="handleClassModal(true)"
      />
    </template>
    <template v-slot:editor-modal>
      <ReportEditorDrawModalVue
        v-if="drawModalState"
        :drawModalState="drawModalState"
        @changeLineThickness="handleLineThickness"
        @saveBox="saveBox"
        @changeDrawModalState="handleDrawModalState"
        @cancelDraw="cancelDraw"
        @saveLine="saveLine"
      />
      <ReportEditorClassModalVue
        ref="report_class_modal"
        v-if="classModalState"
        @closeClassModal="handleClassModal(false)"
        @changeDrawState="handleDrawState"
      />
    </template>
  </ReportEditorVue>
  <MainLoadingVue ref="mainLoading" />
</template>

<script>
import PCReportViewer from "@/module/PointCloud/PointCloudViewReport.js";
import Canvas from "@/module/Canvas.js";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Album from "@/model/Album.js";
import Recognition from "@/model/Recognition.js";
import Segmentation from "@/model/Segmentation.js";
import Detection from "@/model/Detection.js";
import Photo from "@/model/Photo.js";
import Camera from "@/model/Camera.js";
import Info from "@/model/Info.js";
import Point from "@/model/Point.js";
import Model from "@/model/Model.js";
import StorageVolume from "@/model/StorageVolume.js";

import ReportLeftLayoutVue from "./ReportLeftLayout.vue";
import ReportInfoVue from "./ReportInfo.vue";
import ReportDetailsVue from "./ReportDetails.vue";
import ReportEditorVue from "./ReportEditor.vue";
import MainHeaderVue from "../Common/MainHeader.vue";
import MainLoadingVue from "../Common/MainLoading.vue";
import ReportUploadModalVue from "./ReportUploadModal.vue";
import ReportBottomLayoutVue from "./ReportBottomLayout.vue";
import ReportMapListVue from "./ReportMapList.vue";
import ReportAlbumListVue from "./ReportAlbumList.vue";
import ReportHardVolumeVue from "./ReportHardVolume.vue";
import ReportDetailsInfoVue from "./ReportDetailsInfo.vue";
import ReportDetailsCrackVue from "./ReportDetailsCrack.vue";
import ReportEditorCrackVue from "./ReportEditorCrack.vue";
import ReportEditorInfoVue from "./ReportEditorInfo.vue";
import ReportEditorDrawModalVue from "./ReportEditorDrawModal.vue";
import ReportEditorClassModalVue from "./ReportEditorClassModal.vue";

import { mapState } from "vuex";
import { markRaw, nextTick } from "vue";
import { formatISO } from "date-fns";

export default {
  name: "ReportMain",
  data() {
    return {
      navigator: "Report",
      cloudViewer: null,
      reportDetails: false,
      reportEditor: false,
      imageState: false,
      uploadState: false,
      drawState: null,
      drawModalState: false,
      classModalState: false,
      filteredMapList: [],
      albumList: [],
      selectedCamera: null,
      selectedAlbumList: [],
      photoList: [],
      selectedPhoto: null,
      visiblePhotoList: [],
      segmentationList: [],
      selectedSegmentation: null,
      detectionList: [],
      selectedDetection: null,
      infos: [],
      volume: new StorageVolume(),
    };
  },
  components: {
    ReportLeftLayoutVue,
    ReportInfoVue,
    ReportDetailsVue,
    ReportEditorVue,
    MainHeaderVue,
    ReportUploadModalVue,
    MainLoadingVue,
    ReportBottomLayoutVue,
    ReportMapListVue,
    ReportAlbumListVue,
    ReportDetailsInfoVue,
    ReportDetailsCrackVue,
    ReportEditorCrackVue,
    ReportEditorInfoVue,
    ReportEditorDrawModalVue,
    ReportEditorClassModalVue,
    ReportHardVolumeVue,
  },
  computed: {
    ...mapState([
      "user",
      "api",
      "apiIp",
      "apiPort",
      "siteList",
      "selectedSite",
      "isMobile",
    ]),
  },
  watch: {
    selectedPhoto: {
      handler(photo) {
        if (photo) {
          let findedAlbum = this.selectedAlbumList.find(
            (album) => album.id == photo.photo.albumId
          );
          this.selectedCamera = findedAlbum.camera;
        }
      },
    },
  },
  methods: {
    changeReportVue() {
      if (this.selectedPhoto != null) {
        this.reportDetails = !this.reportDetails;
        this.$nextTick(() => {
          if (this.cloudViewer) {
            this.cloudViewer.onWindowResize();
          }
        });
      } else {
        this.$store.commit(
          "openAlert",
          "이미지를 선택하지 않으셨습니다. 이미지를 선택한 뒤 버튼을 눌러주세요."
        );
      }
    },
    handleUploadModal() {
      this.uploadState = !this.uploadState;
    },
    handleImageState(boolean) {
      if (boolean != undefined) {
        this.imageState = boolean;
      } else {
        this.imageState = !this.imageState;
      }
    },
    handleDrawState(method) {
      this.drawState = method;
      if (method) {
        if (method == "crack") {
          this.canvas.setImageEventLine();
        } else {
          this.$refs.report_editor.setPan(false);
          this.canvas.setImageEventBox(method);
        }
      } else {
        this.$refs.report_editor.setPan(true);
        this.canvas.deleteImageEvent();
      }
    },
    handleDrawModalState(string) {
      this.drawModalState = string;
    },
    getSites() {
      if (!this.siteList) {
        this.api.getSites(this.user.id, this.getSitesCallback);
      } else {
        this.getSite();
      }
    },
    getStorageCallback(data) {
      this.volume = new StorageVolume(data.data.result);
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      let setSelectedSite = siteList.find(
        (site) => site.id == this.$route.params.siteId
      );
      this.$store.dispatch("setSelectedSite", setSelectedSite);
      this.getSite();
    },
    getSite() {
      if (
        !this.selectedSite ||
        this.selectedSite.id != this.$route.params.siteId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedSite", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
        this.filteredMapList = mapList.filter((item) => item.url !== "");
        this.cloudViewer.loadPCD(this.selectedSite.map.url);
        this.cloudViewer.createDroneModel();
        this.readAlbumList(this.selectedSite.map);
      }
    },
    selectMap(map) {
      this.cloudViewer.disposeScene();
      if (map) {
        this.$store.dispatch("updateMap", map);
        this.cloudViewer.loadPCD(this.selectedSite.map.url);
        this.cloudViewer.createDroneModel();
      } else {
        this.$store.dispatch("updateMap", null);
      }
      this.readAlbumList(this.selectedSite.map);
    },
    readAlbumList(map) {
      if (map) {
        this.api.getAlbums(
          this.getAlbumsCallback,
          this.$route.params.siteId,
          map.id
        );
      }
    },
    getAlbumsCallback(data) {
      this.albumList = [];
      this.selectedAlbumList = [];
      this.selectedAlbum = null;
      this.photoList = [];
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          this.albumList.push(new Album(result[i]));
          this.api.getCrackRecognitions(
            this.getCrackRecognitionsCallback,
            result[i].id,
            i
          );
          this.api.getOtherRecognitions(
            this.getOtherRecognitionsCallback,
            result[i].id,
            i
          );
        }
        this.api.getFacilityModels(
          this.getFacilityModelsCallback,
          this.$route.params.siteId
        );
      }
    },
    getFacilityModelsCallback(data) {
      let result = data.data.result;
      if (result) {
        this.facilityModel = data.data.result[0];
        this.api.getModels(this.getModelsCallback, this.facilityModel.id);
      }
    },
    getCrackRecognitionsCallback(data, albumIndex) {
      let result = data.data.result;
      if (result) {
        let crackRecognition = result.find(
          (crackRecognition) => crackRecognition.name == "crack"
        );
        if (crackRecognition) {
          this.albumList[albumIndex].crackRecognition = new Recognition(
            crackRecognition
          );
        } else {
          this.albumList[albumIndex].crackRecognition = null;
        }
      }
    },
    getOtherRecognitionsCallback(data, albumIndex) {
      let result = data.data.result;
      if (result) {
        let otherRecognition = result.find(
          (otherRecognition) => otherRecognition.name == "others"
        );
        if (otherRecognition) {
          this.albumList[albumIndex].otherRecognition = new Recognition(
            otherRecognition
          );
        } else {
          this.albumList[albumIndex].otherRecognition = null;
        }
      }
    },
    getModelsCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let model = new Model(result[i]);
          let albumIndex = this.albumList.findIndex(
            (album) => album.id == model.albumId
          );
          if (albumIndex != -1) {
            this.albumList[albumIndex].model = model;
          }
        }
      }
    },
    selectAlbum(album) {
      this.selectedAlbumList.push(album);
      this.api.getCamera(this.getCameraCallback, album);
      this.getAnalyses(album);
    },
    unSelectAlbum(album) {
      let index = this.selectedAlbumList.findIndex(
        (selectedAlbum) => selectedAlbum.id == album.id
      );
      this.selectedAlbumList.splice(index, 1);
      this.segmentationList = this.segmentationList.filter(
        (segmentation) => segmentation.albumId != album.id
      );
      this.photoList = this.photoList.filter(
        (photo) => photo.photo.albumId != album.id
      );
      this.cloudViewer.deleteCameraAngle(album);
      if (this.selectedPhoto) {
        if (album.id == this.selectedPhoto.photo.albumId) {
          this.clickPhoto(null);
        }
      }
    },
    expandAlbumMenu() {
      this.$refs.albumList.expandAlbumMenu(null);
    },
    expandMapMenu() {
      this.$refs.mapList.expandMapMenu(null);
    },
    checkStorage(albumBody, file){
      this.api.getStorage(this.postAlbums, this.user.id, albumBody, file);
    },
    postAlbums(data, albumBody, file) {
      this.volume = new StorageVolume(data.data.result);
      if (
        this.volume.maxStorageSize - this.volume.currentUsage <
        file.size
      ) {
        this.$store.commit(
          "openAlert",
          "남은 용량을 초과하는 파일은 업로드할 수 없습니다."
        );
      } else {
        this.api.postAlbums(this.postAlbumsCallback, albumBody);
      }
    },
    postAlbumsCallback(data) {
      let result = data.data.result;
      this.$refs.uploadModal.postAlbumsCallback(result);
    },
    postAnalysis(albumId) {
      let body = {
        albumId: albumId,
        name: "crack",
        tableName: "",
        type: 1,
        typeName: "Segmentation",
        status: "Running",
        createdDatetime: formatISO(new Date()),
      };
      this.api.postCrackRecognitions(
        this.postCrackRecognitionsCallback,
        albumId,
        body
      );
    },
    postCrackRecognitionsCallback(data) {
      let result = data.data.result;
      var socket = new WebSocket(
        `wss://www.sirius-ex.co.kr:${this.apiPort}/ws/albums/${this.user.id}/analyses`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            analysisId: result.id,
          })
        );
        this.readAlbumList(this.selectedSite.map);
        console.log(`Analysis Web Socket Open`);
      };
      socket.onclose = () => {
        console.log(`Analysis Web Socket Close`);
      };
      socket.onmessage = (event) => {
        if(event.data.includes("[Complete]") || event.data.includes("[Error]")){
          console.log(event.data);
          this.readAlbumList(this.selectedSite.map);
          socket.close();
        }
      };
    },
    getAnalyses(selectedAlbum) {
      if (selectedAlbum) {
        if (
          selectedAlbum.id &&
          selectedAlbum.crackRecognition &&
          selectedAlbum.otherRecognition
        ) {
          this.api.getSegmentations(
            this.getSegmentationsCallback,
            selectedAlbum
          );
        } else {
          this.readsPhotos(selectedAlbum);
        }
      }
    },
    getSegmentationsCallback(data, album) {
      let result = data.data.result;
      let crackCount = 0;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let segmentation = new Segmentation(result[i]);
          this.segmentationList.push(segmentation);
          crackCount += result[i].crackCount;
        }
        let findedAlbum = this.selectedAlbumList.find(
          (selectedAlbum) => selectedAlbum.id == album.id
        );
        findedAlbum.crackCount = crackCount;
      }
      this.api.getDetections(this.getDetectionsCallback, album);
    },
    getDetectionsCallback(data, album) {
      let result = data.data.result;
      let otherCount = 0;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let detection = new Detection(result[i]);
          this.detectionList.push(detection);
          otherCount += result[i].otherCount;
        }
        let findedAlbum = this.selectedAlbumList.find(
          (selectedAlbum) => selectedAlbum.id == album.id
        );
        findedAlbum.otherCount = otherCount;
      }
      this.readsPhotos(album);
    },
    selectAnalyses(photo) {
      this.infos = [];
      let segmentation = this.segmentationList.find(
        (segmentation) => segmentation.photoId == photo.photo.id
      );
      let detection = this.detectionList.find(
        (detection) => detection.photoId == photo.photo.id
      );

      if (segmentation && detection) {
        this.selectedSegmentation = segmentation;
        this.selectedDetection = detection;
        this.getCrackInfos(this.selectedSegmentation);
      } else {
        this.selectedSegmentation = null;
        this.selectedDetection = null;
        this.canvas.imgLoad(
          this.selectedPhoto.photo.albumalbumPhotoResizedPathhotoPath,
          this.infos,
          null
        );
        if (this.reportEditor) {
          this.canvas.editImgLoad(
            this.selectedPhoto.photo.albumPhotoPath,
            this.infos
          );
        }
      }
    },
    getCrackInfos(selectedSegmentation) {
      this.api.getCrackInfos(this.getCrackInfosCallback, selectedSegmentation);
    },
    getCrackInfosCallback(data, selectedSegmentation) {
      let result = data.data;
      if (result) {
        result = result.sort((a, b) => a.mask_id - b.mask_id);
        for (let i = 0; i < result.length; i++) {
          let info = new Info(result[i]);
          info.type = "crack";
          this.infos.push(info);
        }
      }
      this.api.getCrackPoints(
        this.getCrackPointsCallback,
        selectedSegmentation
      );
    },
    getCrackPointsCallback(data) {
      let result = data.data;
      if (result) {
        result = result.sort((a, b) => a.mask_id - b.mask_id);
        for (let i = 0; i < result.length; i++) {
          let info = this.infos.find(
            (info) => info.mask_id == result[i].mask_id
          );
          if (info) {
            info.point = new Point(result[i]);
          }
        }
      }
      this.api.getOtherInfos(
        this.getOtherInfosCallback,
        this.selectedDetection.urisOfOthers
      );
    },
    getOtherInfosCallback(data, index) {
      let result = data.data;
      switch (index) {
        case 0:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "efflorescence";
              this.infos.push(info);
            }
          }
          break;
        case 1:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "spalling";
              this.infos.push(info);
            }
          }
          break;
        case 2:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "rebar";
              this.infos.push(info);
            }
          }
          break;
        case 3:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "leakage";
              this.infos.push(info);
            }
          }
          break;
        case 4:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "net_crack";
              this.infos.push(info);
            }
          }
          break;
        case 5:
          if (result) {
            result = result.sort((a, b) => a.mask_id - b.mask_id);
            for (let i = 0; i < result.length; i++) {
              let info = new Info(result[i]);
              info.type = "etc";
              this.infos.push(info);
            }
          }
          break;
        default:
          break;
      }
      this.canvas.imgLoad(
        this.selectedPhoto.photo.albumalbumPhotoResizedPathhotoPath,
        this.infos,
        this.selectedCamera
      );
      if (this.reportEditor) {
        this.canvas.editImgLoad(
          this.selectedPhoto.photo.albumPhotoPath,
          this.infos
        );
      }
    },
    readsPhotos(album) {
      if (album) {
        this.api.getPhotos(this.getPhotosCallback, album.id);
      }
    },
    getPhotosCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let photo = new Photo(result[i]);
          this.photoList.push(photo);
          let segmentation = this.segmentationList.find(
            (segmentation) => segmentation.photoId == photo.photo.id
          );
          if (segmentation) {
            this.cloudViewer.createCameraAngle(photo, segmentation);
          } else {
            this.cloudViewer.createCameraAngle(photo, null);
          }
          nextTick(() => {
            if (this.$refs.reportBottomLayout)
              this.$refs.reportBottomLayout.photoUpdate();
          });
        }
      }
    },
    clickPhoto(photo) {
      this.selectPhoto(photo);
      if (photo) {
        this.selectAnalyses(photo);
      }
    },
    selectPhoto(photo) {
      if (photo) {
        if (this.selectedPhoto) {
          let prior_index = this.photoList.findIndex(
            (photo) => photo.id == this.selectedPhoto.id
          );
          this.cloudViewer.cancelCameraAngle(prior_index);
        }
        this.selectedPhoto = photo;
        let index = this.photoList.findIndex(
          (photo) => photo.id == this.selectedPhoto.id
        );
        this.cloudViewer.selectCameraAngle(index);
      } else {
        this.selectedPhoto = null;
      }
    },
    getCameraCallback(data, album) {
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) => selectedAlbum.id == album.id
      );
      if (findedAlbum) findedAlbum.camera = new Camera(data.data.result);
    },
    deletePhoto(photo) {
      if (confirm("해당 이미지를 삭제하시겠습니까?")) {
        this.api.deletePhoto(this.deletePhotoCallback, photo);
      } else {
        return false;
      }
    },
    deletePhotoCallback(data, photo) {
      if (data.data.success) {
        let segmentationIndex = this.segmentationList.findIndex(
          (segmentation) => segmentation.photoId == photo.photo.id
        );
        let findedAlbum = this.albumList.find(
          (ablum) => ablum.id == photo.photo.albumId
        );
        if (segmentationIndex != -1) {
          findedAlbum.crackCount -=
            this.segmentationList[segmentationIndex].crackCount;
          this.segmentationList.splice(segmentationIndex, 1);
        }
        let photoIndex = this.photoList.findIndex(
          (findedPhoto) => findedPhoto.id == photo.id
        );
        this.photoList.splice(photoIndex, 1);
        if (this.selectedPhoto && this.selectedPhoto.id == photo.id) {
          this.clickPhoto(null);
          if (this.photoList[photoIndex]) {
            this.clickPhoto(this.photoList[photoIndex]);
          } else {
            this.clickPhoto(this.photoList[photoIndex - 1]);
          }
        }
      }
    },
    handleOpenReportEditor() {
      this.reportEditor = true;
      this.canvas.editImgLoad(
        this.selectedPhoto.photo.albumPhotoPath,
        this.infos
      );
    },
    handleVisiblePhotoList(index) {
      if (!this.visiblePhotoList.includes(index)) {
        this.visiblePhotoList.push(index);
      }
    },
    deleteMap(mapId) {
      this.api.deleteMap(
        this.mapDeleteCallback,
        this.$route.params.siteId,
        mapId
      );
    },
    mapDeleteCallback() {
      this.getMaps();
      this.selectMap(null);
    },
    downloadAlbum(albumId) {
      this.api.downloadAlbum(this.downloadAlbumCallback, albumId);
    },
    downloadAlbumCallback(data) {
      let fileUrl = data.data.result.message;
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "album.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    uploadAlbum(ablumId, formData, progressGage) {
      this.api.uploadAlbum(this.uploadAlbumCallback, ablumId, formData, progressGage);
    },
    uploadAlbumCallback(success, albumId){
      this.$refs.uploadModal.closeUploadModal();
      if(success){
        this.readAlbumList(this.selectedSite.map);
        this.$store.commit(
          "openAlert",
          "사진 업로드가 완료되었습니다."
        );
      }
      else{
        this.$store.commit(
          "openAlert",
          "사진 업로드를 실패하였습니다. 관리자에게 문의하세요."
        );
        this.deleteAlbum(albumId);
      }
      this.api.getStorage(this.getStorageCallback, this.user.id);
    },
    deleteAlbum(albumId) {
      this.api.deleteAlbum(this.deleteAlbumCallback, albumId);
    },
    deleteAlbumCallback() {
      this.readAlbumList(this.selectedSite.map);
    },
    deleteCrack(index) {
      if (this.infos[index].type == "crack") {
        this.canvas.deleteCrack(1, 1, "edit", index);
        this.canvas.setImgData("edit");
      }
    },
    deleteCrackAll() {
      this.canvas.deleteCrackAll(1, 1, "edit");
      this.canvas.setImgData("eidt");
    },
    drawCrack(index) {
      if (this.infos[index].type == "crack") {
        this.canvas.drawCrack(1, 1, "edit", index);
        this.canvas.setImgData("edit");
      }
    },
    drawCrackAll() {
      this.canvas.drawCrackAll(1, 1, "edit");
      this.canvas.setImgData("eidt");
    },
    handleClassModal(boolean) {
      if (boolean) {
        this.handleImageState(false);
      }
      this.classModalState = boolean;
    },
    handleLineThickness(thickness) {
      this.canvas.drawLine(thickness);
    },
    cancelDraw() {
      this.canvas.setImgData("edit");
    },
    saveLine() {
      this.canvas.saveLine();
    },
    saveBox(method) {
      this.canvas.saveBox(method);
    },
    analysisSave(indexArray) {
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) => selectedAlbum.id == this.selectedSegmentation.albumId
      );
      let tempCrackCount = this.infos.reduce((count, info) => {
        return info.type == "crack" ? count + 1 : count;
      }, 0);
      findedAlbum.crackCount -= tempCrackCount;
      this.canvas.saveJson(indexArray, this.analysisSaveCallback);
    },
    analysisSaveCallback(infos) {
      let findedAlbum = this.selectedAlbumList.find(
        (selectedAlbum) => selectedAlbum.id == this.selectedSegmentation.albumId
      );
      let tempCrackCount = infos.reduce((count, info) => {
        return info.type == "crack" ? count + 1 : count;
      }, 0);
      findedAlbum.crackCount += tempCrackCount;
      let photoIndex = this.photoList.findIndex(
        (photo) => photo.id == this.selectedPhoto.id
      );

      let crackInfos = [];
      let crackPoints = [];
      let count = 1;
      for (let i = 0; i < infos.length; i++) {
        if (infos[i].type === "crack") {
          let infoData = {
            box: infos[i].box,
            crack_length: infos[i].crack_length,
            crack_width: infos[i].crack_width,
            mask_id: count,
            ...(infos[i].cross_point_cloud && {
              cross_point_cloud: infos[i].cross_point_cloud,
            }),
            ...(infos[i].distance && { distance: infos[i].distance }),
          };

          let pointData = {
            mask_id: count,
            points: infos[i].point.points,
          };

          crackInfos.push(infoData);
          crackPoints.push(pointData);
          count++;
        }
      }
      if (crackInfos.length != 0) {
        this.cloudViewer.updateCameraAngleCrack(photoIndex, true);
      } else {
        this.cloudViewer.updateCameraAngleCrack(photoIndex, false);
      }
      this.api.putSegmentations(
        this.putSegmentationsCallback,
        this.selectedSegmentation.albumId,
        this.selectedSegmentation,
        crackInfos,
        crackPoints
      );
    },
    putSegmentationsCallback() {
      let efflorescenceInfo = [];
      let spallingInfo = [];
      let rebarInfo = [];
      let leakageInfo = [];
      let net_crackInfo = [];
      let etcInfo = [];

      let efflorescenceCount = 1;
      let spallingCount = 1;
      let rebarCount = 1;
      let leakageCount = 1;
      let net_crackCount = 1;
      let etcCount = 1;
      for (let i = 0; i < this.infos.length; i++) {
        if (this.infos[i].type != "crack") {
          let infoData = {
            box: this.infos[i].box,
            crack_length: this.infos[i].crack_length,
            crack_width: this.infos[i].crack_width,
            ...(this.infos[i].cross_point_cloud && {
              cross_point_cloud: this.infos[i].cross_point_cloud,
            }),
            ...(this.infos[i].distance && { distance: this.infos[i].distance }),
          };
          switch (this.infos[i].type) {
            case "efflorescence":
              infoData.mask_id = efflorescenceCount;
              efflorescenceInfo.push(infoData);
              efflorescenceCount++;
              break;
            case "spalling":
              infoData.mask_id = spallingCount;
              spallingInfo.push(infoData);
              spallingCount++;
              break;
            case "rebar":
              infoData.mask_id = rebarCount;
              rebarInfo.push(infoData);
              rebarCount++;
              break;
            case "leakage":
              infoData.mask_id = leakageCount;
              leakageInfo.push(infoData);
              leakageCount++;
              break;
            case "net_crack":
              infoData.mask_id = net_crackCount;
              net_crackInfo.push(infoData);
              net_crackCount++;
              break;
            case "etc":
              infoData.mask_id = etcCount;
              etcInfo.push(infoData);
              etcCount++;
              break;
            default:
              break;
          }
        }
      }
      this.api.putDetections(
        this.putDetectionsCallback,
        this.selectedDetection.albumId,
        this.selectedDetection,
        JSON.stringify([
          efflorescenceInfo,
          spallingInfo,
          rebarInfo,
          leakageInfo,
          net_crackInfo,
          etcInfo,
        ])
      );
    },
    putDetectionsCallback() {
      this.clickPhoto(this.selectedPhoto);
    },
    cleanUp() {
      if (this.cloudViewer) {
        this.cloudViewer.dispose();
        this.cloudViewer = null;
      }
    },
  },
  created() {
    this.$store.dispatch("checkDeviceType");
  },
  mounted() {
    this.cloudViewer = markRaw(
      new PCReportViewer(this.$refs.cloud_viewer, this.$refs.mainLoading)
    );
    this.canvas = new Canvas(this.apiIp, this.apiPort);
    this.api.putUser({"taskStatus" : "reporting"}, this.user.id);
    this.getSites();
    this.api.getStorage(this.getStorageCallback, this.user.id);
    if (!this.isMobile) {
      this.$refs.cloud_viewer.addEventListener("click", (event) => {
        if (this.cloudViewer) {
          let index = this.cloudViewer.raycaster(event);
          if (index) {
            this.clickPhoto(this.photoList[index]);
          }
        }
      });
    }
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.cloud_viewer {
  position: relative;
  width: 100%;
  height: 100%;
}
.cloud_viewer.detail {
  position: relative;
  width: calc(100% - 520px);
  height: 100%;
}
@media (max-width: 1300px) {
  .cloud_viewer.detail {
    position: relative;
    width: 60%;
    height: 100%;
  }
}
.report_bottom_content_img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
</style>
