<template>
  <div class="report_list_container">
    <div class="report_list_wrap">
      <div class="report_list_title">앨범 목록</div>
      <div
        class="report_list_add_button button"
        :class="{ on: uploadState }"
        @click="openUploadModal"
      ></div>
    </div>
    <div class="report_map_item_list">
      <div
        v-for="(album, albumIndex) in albumList"
        :key="albumIndex"
        class="report_album_item"
      >
        <div
          class="report_item_check_box"
          @click="selectAlbum(album)"
          :class="{ on: checkSelectedAlbumList(album.id) }"
        ></div>
        <div class="report_item_name">{{ album.name }}</div>
        <div
          class="report_expand_menu_button button"
          @click="expandAlbumMenu(album)"
          :class="{ on: expandAlbum == album }"
        ></div>
      </div>
    </div>
  </div>
  <div
    class="report_ablum_expand_menu_modal"
    v-if="expandAlbum"
    :style="{ height: getHeight }"
  >
    <div class="report_ablum_ananlysis_state">분석상태 : {{ getStatus }}</div>
    <!-- <div
      class="report_ablum_button button"
      v-if="expandAlbum.model"
      @click.stop="routerPattern(expandAlbum.id, expandAlbum.model.id)"
    >
      균열망도
    </div> -->
    <div
      class="report_ablum_button button"
      @click.stop="downloadAlbum(expandAlbum.id)"
    >
      다운로드
    </div>
    <div
      v-if="!expandAlbum.crackRecognition"
      class="report_analysis_button button"
      @click.stop="clickAnalysis(expandAlbum.id)"
    >
      분석하기
    </div>
    <div
      class="report_ablum_button button"
      @click.stop="deleteAlbum(expandAlbum.id)"
    >
      삭제하기
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user", "selectedSite"]),
    getStatus() {
      if (!this.expandAlbum?.crackRecognition) {
        return "미분석";
      } else if (this.expandAlbum.crackRecognition.status == "Completed") {
        return "완료";
      } else if (this.expandAlbum.crackRecognition.status == "Running") {
        return "진행";
      } else {
        return "오류";
      }
    },
    getHeight() {
      let count = 0;
      // if (this.expandAlbum?.model) {
      //   count++;
      // }
      if (!this.expandAlbum?.crackRecognition) {
        count++;
      }
      return `${140 + count * 40}px`;
    },
  },
  emits: [
    "openUploadModal",
    "selectAlbum",
    "unSelectAlbum",
    "deleteAlbum",
    "downloadAlbum",
    "expandMapMenu",
    "clickAnalysis",
  ],
  data() {
    return {
      ellipsisModalState: false,
      expandAlbum: null,
    };
  },
  watch: {
    albumList: {
      handler() {
        this.expandAlbum = null;
      },
    },
  },
  props: {
    uploadState: Boolean,
    albumList: Array,
    selectedAlbumList: Array,
  },
  methods: {
    checkSelectedAlbumList(albumId) {
      let findAblum = this.selectedAlbumList.find(
        (ablum) => ablum.id == albumId
      );
      return findAblum ? true : false;
    },
    openUploadModal() {
      if (this.selectedSite.map.id) {
        if (this.albumList.length < 100) {
          this.$emit("openUploadModal");
        } else {
          this.$store.commit(
            "openAlert",
            "맵당 앨범은 최대 100개까지 등록이 가능합니다. 앨범을 추가 등록하시려면 기존 앨범을 삭제해 주세요."
          );
        }
      } else {
        this.$store.commit(
          "openAlert",
          "업로드할 맵을 선택해주세요."
        );
      }
    },
    selectAlbum(album) {
      let check = this.checkSelectedAlbumList(album.id);
      if (check) {
        this.$emit("unSelectAlbum", album);
      } else {
        this.$emit("selectAlbum", album);
      }
    },
    expandAlbumMenu(album) {
      if (album == this.expandAlbum) {
        this.expandAlbum = null;
      } else {
        this.$emit("expandMapMenu");
        this.expandAlbum = album;
      }
    },
    clickAnalysis(albumId) {
      this.$emit("clickAnalysis", albumId);
    },
    downloadAlbum(albumId) {
      if (confirm("해당 앨범을 다운로드하시겠습니까?")) {
        this.$emit("downloadAlbum", albumId);
      } else {
        return false;
      }
    },
    routerPattern(albumId, modelId) {
      this.$router.push({
        name: "pattern",
        params: {
          siteId: this.selectedSite.id,
          mapId: this.selectedSite.map.id,
          albumId: albumId,
          modelId: modelId,
        },
      });
    },
    deleteAlbum(albumId) {
      if(this.user.role == 'normal'){
        this.$store.commit(
          "openAlert",
          `권한이 없습니다. 관리자에게 문의해주세요.`
        );
      }
      else{
        if (confirm("해당 앨범을 삭제하시겠습니까?")) {
          this.$emit("deleteAlbum", albumId);
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.report_list_container {
  height: calc((100% - 80px) / 2);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.report_list_wrap {
  display: flex;
  height: calc(100% / 8);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-bottom: 10px;
}
.report_list_title {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
}
.report_list_add_button {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/circlePlus.svg");
  background-size: 15px 15px;
}
.report_list_add_button.on {
  background-image: url("@/../public/images/circlePlus_hover.svg");
  background-size: 15px 15px;
}
.report_list_add_button:hover {
  background-image: url("@/../public/images/circlePlus_hover.svg");
  background-size: 15px 15px;
}
.report_item_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px;
}
.report_album_item {
  display: flex;
  flex-shrink: 0;
  width: calc(100% - 4px);
  height: 40px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: $main_white;
  font-size: 1.6rem;
  margin: 1px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.report_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  font-size: 1.6rem;
  color: $main_white;
  cursor: pointer;
  margin-bottom: 10px;
}
.report_item:last-of-type() {
  margin-bottom: 0px;
}
.report_item:hover {
  border: 1px solid $main_color;
  color: $main_color;
}
.report_item.active {
  border: 1px solid $main_color;
  color: $main_color;
}
.report_item_top_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 10px;
}
.report_item_check_box {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.report_item_check_box.on {
  background-image: url("@/../public/images/check.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.report_item_name {
  padding-left: 10px;
  width: calc(100% - 65px);
}
.report_item_button_wrap {
  display: flex;
  width: 100%;
  height: 50%;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: center;
}
.report_ablum_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.report_analysis_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.report_analysis_completed_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
}
.report_analysis_completed_button:hover {
  background-color: #171717;
  color: rgba(1, 250, 254, 1);
  cursor: default;
}
.report_ablum_expand_menu_modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: calc(100% / 4 + 25px);
  top: calc(100% / 16 * 9 + 71px);
  width: 150px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  z-index: 6;
}
@media (min-width: 1250px) {
  .report_ablum_expand_menu_modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 335px;
    top: calc(100% / 16 * 9 + 71px);
    width: 150px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 6;
  }
}
.report_ablum_ananlysis_state {
  @include flexbox;
  width: 100%;
  height: 40px;
  color: $main_white;
  font-size: 1.8rem;
}
</style>
