import axios from "axios";
import API from "@/shared/constant/api";
import { getCurrentTime } from "../util/getCurrentTime";
/**
 * 기본 정보를 instance화 해서 재사용한다.
 */
const axiosInstance = axios.create({
  // baseURL: `http://${API.IP}:${API.PORT}/api`,
  baseURL: `https://www.sirius-ex.co.kr:${API.PORT}/api`,
  timeout: 10000,
  timeoutErrorMessage:
    "요청 시간이 10초 이상 초과되었습니다. 다시 시도해주세요.",
});

export const postLogin = async (hashUserId, hashUserPassword) => {
  try {
    const response = await axiosInstance.post(`/users/verification`, {
      userId: hashUserId,
      userPw: hashUserPassword,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const putLogin = async (userId, userInfo) => {
  try {
    const response = await axiosInstance.put(`/users/${userId}`, userInfo);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getUserList = async (affiliationId) => {
  try {
    const response = await axiosInstance.get(
      `/users?affiliationId=${affiliationId}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postUser = async (newUserInfo) => {
  try {
    const response = await axiosInstance.post(`/users`, newUserInfo);

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const patchUser = async (userId, newUserInfo) => {
  try {
    const response = await axiosInstance.patch(`/users/${userId}`, newUserInfo);

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postUserThumbnail = async (userId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/thumbnails`,
      formData
    );

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const deleteUser = async (adminId, userId) => {
  const request = { adminId, userId };

  try {
    const response = await axiosInstance.delete(`/users`, { data: request });
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getFacilitiesList = async (userId) => {
  try {
    const response = await axiosInstance.get(`/facilities?userId=${userId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getFacility = async (facilityId) => {
  try {
    const response = await axiosInstance.get(`/facilities/${facilityId}`);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postFacility = async ({
  userId,
  name,
  description,
  location,
  typeName,
  createdDatetime,
}) => {
  try {
    const response = await axiosInstance.post(`/facilities?userId=${userId}`, {
      userId,
      name,
      description,
      location,
      typeName,
      createdDatetime,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const deleteFacility = async (facilityId) => {
  try {
    const response = await axiosInstance.delete(`/facilities/${facilityId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const putFacility = async (facilityId, editInfo) => {
  try {
    const response = await axiosInstance.put(
      `/facilities/${facilityId}`,
      editInfo
    );

    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postCreateThumbnail = async (facilityId, file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/facilities/${facilityId}/thumbnails`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getMaps = async (facilityId) => {
  try {
    const response = await axiosInstance.get(`/facilities/${facilityId}/maps`);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getMap = async (facilityId, mapId) => {
  try {
    const response = await axiosInstance.get(
      `/facilities/${facilityId}/maps/${mapId}`
    );
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postMap = async (facilityId) => {
  try {
    const response = await axiosInstance.post(
      `/facilities/${facilityId}/maps`,
      {
        facilityId: facilityId,
        name: "GlobalMap.pcd",
        url: "",
        createdDatetime: getCurrentTime(),
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAlbums = async (facilityId, mapId) => {
  try {
    const response = await axiosInstance.get(
      `/albums?facility_id=${facilityId}&facility_map_id=${mapId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAnalysesList = async (albumId) => {
  try {
    const response = await axiosInstance.get(
      `/albums/${albumId}/crack-recognitions`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDrones = async (affiliationId) => {
  try {
    const response = await axiosInstance.get(
      `/drones?affiliationId=${affiliationId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDroneSystemLogList = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/drone-systems/logs?userId=${userId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
