<template>
  <ul class="facility_list" v-if="facilities">
    <li
      v-for="facility in facilities[this.currentPage - 1]"
      :id="facility?.id"
      :key="facility?.id"
      class="facility"
      @click="clickFacility(facility)"
    >
      <div class="info_area">
        <div class="title_box">
          <div class="name_box">
            <h5>{{ facility?.name }} {{ "(" + facility.typeName + ")" }}</h5>
            <div class="func_box" :class="isTouchDevice ? 'touch_device' : ''">
              <button
                type="button"
                class="remove"
                @click="removeFacility($event, facility.id, facility.name)"
              >
                삭제
              </button>
              <button
                type="button"
                class="edit"
                @click="editFacility($event, facility)"
              >
                수정
              </button>
            </div>
          </div>
          <div
            v-if="facility.facilityMapCount === 0"
            class="state_badge yellow"
          >
            매핑 필요
          </div>
          <div
            v-else-if="
              facility.facilityMapCount > 0 && facility.albumCount === 0
            "
            class="state_badge orange"
          >
            촬영 필요
          </div>
          <template v-else>
            <div
              v-if="facility.recognitionCount === 0"
              class="state_badge pink"
            >
              분석 필요
            </div>
            <div
              v-else-if="
                facility.recognitionCount > 0 &&
                isRunningState(facility.albums?.albumsInfo)
              "
              class="state_badge pink"
            >
              분석 진행중
            </div>
            <div v-else class="state_badge blue">분석 완료</div>
          </template>
        </div>
        <div class="desc">
          {{ facility?.description ? facility.description : `-` }}
        </div>
        <div class="small_info_list">
          <div class="info_box">
            <p>생성일</p>

            <span v-if="facility?.createdDatetime !== null">{{
              formatDateTime(facility?.createdDatetime)
            }}</span>
            <span v-else>-</span>
          </div>
          <div class="info_box">
            <p>지역</p>
            <span>{{ facility?.location }}</span>
          </div>
          <div class="info_box">
            <p>담당자</p>
            <span v-if="facility.fullName">{{ facility.fullName }}</span>
            <span v-else>-</span>
          </div>
          <div class="info_box">
            <p>매핑</p>
            <span>{{ facility.facilityMapCount }}건</span>
          </div>
          <div class="info_box">
            <p>촬영</p>
            <span>{{ facility.albumCount }}건</span>
          </div>
          <div class="info_box">
            <p>분석</p>
            <span>{{ facility.recognitionCount }}건</span>
          </div>
        </div>
      </div>
      <div class="divided_line"></div>
      <div class="desc_area">
        <p v-if="facility.facilityMapCount === 0">
          새로 등록된 시설물 입니다.<br />맵을 생성하여 점검 준비를 해보세요.
        </p>
        <p
          v-else-if="
            facility.facilityMapCount > 0 &&
            facility.albumCount === 0 &&
            facility.recognitionCount === 0
          "
        >
          매핑이 완료된 시설물입니다.<br />이제 촬영을 통해 분석을 준비해
          보세요.
        </p>
        <!-- <template
          v-else-if="
            facility.facilityMapCount  > 0 &&
            facility.albumCount > 0 &&
            facility.recognitionCount === 0
          "
        >
          <p v-if="facility.albums?.albumsInfo === null">
            분석을 기다리는 앨범이 있어요.<br />AI 분석으로 결함을 찾아보세요.
          </p>
        </template> -->
        <template v-else>
          <p v-if="facility.recognitionCount === 0">
            분석을 기다리는 앨범이 있어요.<br />AI 분석으로 결함을 찾아보세요.
          </p>
          <p
            v-else-if="
              facility.recognitionCount > 0 &&
              isRunningState(facility.albums?.albumsInfo)
            "
          >
            분석 진행중인 앨범이 있습니다.<br />완료 될때 까지 조금만
            기다려주세요.
          </p>
          <p v-else>
            분석 완료된 앨범이 있습니다.<br />레포트 페이지에서 확인해 보세요!
          </p>
        </template>
        <div class="icon_box"></div>
      </div>
    </li>
  </ul>
</template>
<script>
import { filterEmptyMapUrl } from "@/shared/util/filterEmptyMapUrl";
import { formatDateTime } from "@/shared/util/formatDateTime";
import { isRunningState } from "@/shared/util/isRunningState";
import { mapState } from "vuex";

export default {
  name: "FacilityList",
  emits: ["handleClickFacility", "handleRemoveFacility", "handleOpenEditForm"],
  computed: {
    isTouchDevice() {
      return this.$store.getters.isTouchDevice;
    },
    ...mapState(["facilityFilterValue", "user", "selectedFacility"]),
  },
  props: {
    facilities: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { emptyMapUrl: null };
  },
  methods: {
    filterEmptyMapUrl,
    isRunningState,
    formatDateTime,
    clickFacility(facility) {
      this.$store.commit("setSelectedFacility", facility);
      this.$emit("handleClickFacility", facility);
    },
    removeFacility(event, facilityId, facilityName) {
      event.stopPropagation();
      if(this.user.role == 'normal'){
        this.$store.commit(
          "openAlert",
          `권한이 없습니다. 관리자에게 문의해주세요.`
        );
      }
      else{
        this.$emit("handleRemoveFacility", { facilityId, facilityName });
      }
    },
    editFacility(event, facility) {
      event.stopPropagation();
      if(this.user.role == 'normal'){
        this.$store.commit(
          "openAlert",
          `권한이 없습니다. 관리자에게 문의해주세요.`
        );
      }
      else{
        this.$store.commit("setSelectedFacility", facility);
        this.$store.commit("setSelectedSite", facility);
        this.$emit("handleOpenEditForm");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.facility_list {
  margin-top: 20px;
}
.facility {
  @include flexbox(stretch, space-between, row);
  gap: 30px;
  padding: 16px 30px;
  background: $black_252530;
  border-radius: 12px;
  transition: transform 0.12s;
  cursor: pointer;

  @include mobile {
    @include flexbox(flex-start, flex-start, column);
    gap: 16px;
    padding: 20px;
  }

  @include hoverPointer {
    &:hover {
      transform: scale(1.01);

      .info_area {
        .title_box {
          .name_box {
            .func_box {
              button {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  & ~ .facility {
    margin-top: 10px;
  }

  .divided_line {
    width: 1px;
    height: auto;
    background: $black_353542;

    @include mobile {
      width: 100%;
      height: 1px;
    }
  }

  .info_area {
    width: calc(70% - 1px);

    @include mobile {
      width: 100%;
    }

    .title_box {
      @include flexbox(center, space-between, row);
      margin-bottom: 6px;

      @include mobile {
        @include flexbox(flex-start, flex-start, column-reverse);
        gap: 16px;
      }

      .name_box {
        @include flexbox(center, flex-start, row);
        gap: 12px;

        .func_box {
          @include flexbox(center, flex-start, row);
          gap: 18px;

          &.touch_device {
            button {
              opacity: 1;
              visibility: visible;
            }
          }

          button {
            opacity: 0;
            visibility: hidden;
            width: 18px;
            height: 18px;
            font-size: 1.4rem;
            color: $gray_9fa6b2;

            @include hoverPointer {
              &:hover {
                font-weight: 700;
                text-decoration: underline;
              }
            }
          }

          .edit {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 4px;
              left: -6px;
              display: inline-block;
              width: 1px;
              height: 11px;
              background: $gray_9fa6b2;
            }
          }
        }
      }

      h5 {
        font-size: 2rem;
        font-weight: 700;

        @include mobile {
          font-size: 1.8rem;
        }
      }
    }

    .desc {
      font-size: 1.4rem;
      margin-bottom: 14px;
    }

    .small_info_list {
      @include flexbox(center, flex-start, row);
      flex-wrap: wrap;
      gap: 12px;

      .info_box {
        @include flexbox(center, flex-start, row);
        gap: 4px;
        font-size: 1.2rem;

        p {
          color: $gray_6e6e82;
        }

        span {
          color: $gray_9fa6b2;
        }
      }
    }
  }

  .desc_area {
    width: calc(30% - 1px);
    @include flexbox(center, space-between, row);

    @include mobile {
      width: 100%;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.4;
    }

    .icon_box {
      width: 12px;
      height: 20px;
      background: url(@/../public/images/rightArrow_thin_white.svg) no-repeat
        center;
      background-size: contain;
    }
  }
}
</style>
