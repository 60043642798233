<template>
  <div class="report_editor_draw_modal_background"></div>
  <div class="report_editor_draw_modal">
    <div class="report_editor_draw_modal_header">
      {{ drawModalState == "crack" ? "선 설정" : "박스 설정" }}
    </div>
    <div class="report_editor_draw_modal_content_wrap">
      <div
        class="report_editor_draw_modal_input_wrap"
        v-if="drawModalState == 'crack'"
      >
        <div class="report_editor_draw_modal_title">두께 :</div>
        <div class="report_editor_draw_modal_input input">{{ thickness }}</div>
        <div class="report_editor_draw_modal_unit">PX</div>
        <div
          class="report_editor_draw_modal_control button"
          id="report_editor_draw_modal_control_up"
          @click="handleThickness(true)"
        ></div>
        <div
          class="report_editor_draw_modal_control button"
          id="report_editor_draw_modal_control_down"
          @click="handleThickness(false)"
        ></div>
      </div>
      <div class="report_editor_draw_modal_button_wrap">
        <div
          class="report_editor_draw_modal_button button"
          @click="drawModalApply"
        >
          적용
        </div>
        <div
          class="report_editor_draw_modal_button button"
          @click="drawModalCancel"
        >
          취소
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    "changeLineThickness",
    "changeDrawModalState",
    "cancelDraw",
    "saveLine",
    "saveBox",
  ],
  data() {
    return {
      thickness: 2,
    };
  },
  props: {
    drawModalState: String,
  },
  methods: {
    drawModalCancel() {
      this.thickness = 2;
      this.$emit("changeDrawModalState", null);
      this.$emit("cancelDraw");
    },
    drawModalApply() {
      this.thickness = 2;
      if (this.drawModalState == "crack") {
        this.$emit("saveLine");
      } else {
        this.$emit("saveBox", this.drawModalState);
      }
      this.$emit("changeDrawModalState", null);
    },
    changeThickness(thickness) {
      if (thickness < 2 || thickness > 20) {
        this.$store.commit(
          "openAlert",
          `${2}~${20}까지의 값만 입력할 수 있습니다.`
        );
        this.thickness = 2;
      } else {
        this.thickness = thickness;
        this.$emit("changeLineThickness", this.thickness);
      }
    },
    handleThickness(Boolean) {
      if (Boolean) {
        this.changeThickness(parseFloat(this.thickness) + 1);
      } else {
        this.changeThickness(parseFloat(this.thickness) - 1);
      }
    },
  },
};
</script>

<style lang="scss">
.report_editor_draw_modal_background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.3);
  z-index: 21;
}
.report_editor_draw_modal {
  position: absolute;
  left: calc(50% - 225px);
  top: calc(50% - 45px);
  width: 450px;
  height: 90px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 22;
}
.report_editor_draw_modal_header {
  @include flexbox;
  color: $main_white;
  font-size: 2rem;
  background-color: rgba(32, 32, 32, 0.85);
  width: 100%;
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.report_editor_draw_modal_content_wrap {
  @include flexbox;
  width: 100%;
  height: 50px;
  padding: 0px 20px 0px 20px;
}
.report_editor_draw_modal_input_wrap {
  @include flexbox(center, space-between, row);
  width: calc(100% - 180px);
  margin-right: 20px;
}
.report_editor_draw_modal_title {
  width: 80px;
  height: 1.4rem;
  color: $main_white;
  font-size: 1.4rem;
}
.report_editor_draw_modal_unit {
  width: 30px;
  height: 1.4rem;
  color: $main_white;
  font-size: 1.4rem;
}
.report_editor_draw_modal_input {
  @include flexbox;
  width: calc(100% - 190px);
  height: 30px;
  font-size: 1.4rem;
}
.report_editor_draw_modal_control {
  display: flex;
  width: 30px;
  height: 30px;
}
#report_editor_draw_modal_control_up {
  background-image: url("@/../public/images/arrowUp.svg");
  background-size: 15px 15px;
}
#report_editor_draw_modal_control_up:hover {
  background-image: url("@/../public/images/arrowUp_hover.svg");
  background-size: 15px 15px;
}
#report_editor_draw_modal_control_down {
  background-image: url("@/../public/images/arrowDown.svg");
  background-size: 15px 15px;
}
#report_editor_draw_modal_control_down:hover {
  background-image: url("@/../public/images/arrowDown_hover.svg");
  background-size: 15px 15px;
}
.report_editor_draw_modal_button_wrap {
  display: flex;
  width: 130px;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.report_editor_draw_modal_button {
  @include flexbox;
  width: 60px;
  height: 30px;
  font-size: 1.4rem;
}
</style>
