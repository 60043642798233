<template>
  <section class="sec_facility_area">
    <div class="main_title_box">
      <h3>시설물을 선택하고 원하는 작업을 수행해 보세요.</h3>
      <button
        type="button"
        @click="clickAddFacility"
        class="default_border_hover_button"
      >
        시설물 추가하기
      </button>
    </div>
    <slot></slot>
  </section>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'FacilityListManager',
  emits: ['handleOpenAddForm'],
  computed: {
    isTouchDevice() {
      return this.$store.getters.isTouchDevice;
    },
    ...mapState(['facilityFilterValue','user']),
  },
  methods : {
    clickAddFacility(){
      if(this.user.role == 'normal'){
        this.$store.commit(
          "openAlert",
          `권한이 없습니다. 관리자에게 문의해주세요.`
        );
      }
      else{
        this.$emit('handleOpenAddForm');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main_title_box {
  @include flexbox(center, space-between, row);
  margin-bottom: 40px;

  @include response(400px) {
    @include flexbox(flex-start, flex-start, column);
    gap: 12px;
  }
}

h3 {
  font-weight: 400;
  font-size: 2.4rem;

  @include mobile {
    font-size: 2rem;
  }
}
</style>
