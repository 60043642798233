<template>
  <section class="no_facility">
    <div class="icon_box"></div>
    <h3>아직 등록된 시설물이 없네요!</h3>
    <h3>시설물을 등록하고 시리우스에서 제공하는 AI 기술을 경험해 보세요.</h3>
    <button
      type="button"
      @click="clickAddFacility"
      class="default_border_hover_button add_facility"
    >
      시설물 추가하기
    </button>
  </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "NoFacilitiesMessage",
  emits: ["handleOpenAddForm"],
  computed: {
    ...mapState(["user", "facilityTypeCounts"]),
  },
  props: {
    totalFacility: {
      type: Number,
      required: true,
    },
  },
  methods : {
    clickAddFacility(){
      if(this.user.role == 'normal'){
        this.$store.commit(
          "openAlert",
          `권한이 없습니다. 관리자에게 문의해주세요.`
        );
      }
      else{
        this.$emit('handleOpenAddForm');
      }
    }
  },
};
</script>
<style lang="scss">
.no_facility {
  text-align: center;
}
h3 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.5;

  @include mobile {
    font-size: 2rem;
  }
}
.icon_box {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url("@/../public/images/location_exclamation.svg") no-repeat
    center;
  background-size: contain;
  margin-bottom: 20px;
}
.add_facility {
  margin-top: 30px;
  font-size: 1.6rem;
}
</style>
