<template>
  <div
    class="home_site_container"
    :ref="`siteContainer${site.id}`"
    @click="clickSite(site)"
    :style="getBackground(site.thumbnailUrl)"
  >
    <div
      class="home_site_name"
      :style="{ width: getWidth(site.name) }"
      :class="{
        active_site: selectedSite ? site.id == selectedSite.id : false,
      }"
    >
      {{ site.name }}
    </div>
    <div
      class="home_delete_button button"
      @click.stop="clickDeleteSite(site)"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  emits: ["clickSite", "deleteSite"],
  computed: {
    ...mapState(["selectedSite"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 14 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
    getBackground() {
      return (thumbnailUrl) => {
        if (thumbnailUrl) {
          return { backgroundImage: `url(${thumbnailUrl})` };
        } else {
          return null;
        }
      };
    },
  },
  watch: {
    selectedSite: {
      handler(selectedSite) {
        if (selectedSite) {
          let selectedElemet = this.$refs[`siteContainer${selectedSite.id}`];
          if (selectedElemet) {
            selectedElemet.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        } else {
          return false;
        }
      },
    },
  },
  props: {
    site: Object,
  },
  methods: {
    clickSite(site) {
      this.$emit("clickSite", site);
    },
    clickDeleteSite(site) {
      if (confirm("해당 시설물을 삭제하시겠습니까?")) {
        this.$emit("deleteSite", site);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.home_site_container {
  height: calc(100% / 4);
  width: calc(100% - 36px);
  margin: 20px 0px 0px 20px;
  background: black no-repeat center;
  background-size: cover;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}
.home_site_name {
  height: 25px;
  width: 20px;
  @include flexbox;
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: $main_white;
  font-size: 1.4rem;
  font-weight: bold;
  background: #171717;
  border-radius: 5px;
}
.active_site {
  color: #171717;
  background: rgba(1, 250, 254, 1);
}
.home_delete_button {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 5px;
  right: 10px;
  background-image: url("@/../public/images/trash.svg");
  background-size: 15px 15px;
}
.home_delete_button:hover {
  background-image: url("@/../public/images/trash_hover.svg");
  background-size: 15px 15px;
}
</style>
