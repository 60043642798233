import axios from 'axios';
import NetworkMap from '@/model/NetworkMap';
import NetworkMapSummary from '@/model/NetworkMapSummary';
import PrimaryDefect from '@/model/PrimaryDefect';
import SSE from "@/module/Communication/SSE.js"

class RESTAPI{
  constructor(ip, port){
    this.url ='https://www.sirius-ex.co.kr'
    this.apiIp = ip;
    this.apiPort = port;
  }
  getUser(callback,userId){
    axios.get(`${this.url}:${this.apiPort}/api/users/${userId}`)
    .then((data)=>{
      callback(data);
    })
    .catch();
  }
  putUser(body,userId){
    axios.patch(`${this.url}:${this.apiPort}/api/users/${userId}`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
    .then()
    .catch();
  }
  postLogin(callback,username,hashUser,hashPassword){
    axios.post(`${this.url}:${this.apiPort}/api/users/verification`,{
      "userId" : hashUser,
      "userPw" : hashPassword
    })
    .then((data)=>{
      callback(data, username);
    })
    .catch();
  }
  postSite(callback, body, file){
    axios.post(`${this.url}:${this.apiPort}/api/facilities`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((data)=>{
      callback(data, file);
    })
    .catch();
  }
  getSites(userId,callback){
    axios.get(`${this.url}:${this.apiPort}/api/facilities?userId=${userId}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getSite(callback, siteId){
    axios.get(`${this.url}:${this.apiPort}/api/facilities/${siteId}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  putSite(callback, site, body, file){
    axios.put(`${this.url}:${this.apiPort}/api/facilities/${site.id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((data)=>{
      callback(data, file);
    })
    .catch();
  }
  deleteSite(callback,site){
    axios.delete(`${this.url}:${this.apiPort}/api/facilities/${site.id}`)
    .then(()=>{
      callback(site);
    })
    .catch()
  }
  postSiteThumbnail(callback, siteId, formData){
    axios.post(`${this.url}:${this.apiPort}/api/facilities/${siteId}/thumbnails`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
    .then(()=>{
      callback();
    })
    .catch()
  }
  postMap(callback,siteId,createdDatetime){
    axios.post(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps`,{
      "facilityId": siteId,
      "name": "GlobalMap.pcd",
      "url": "",
      "createdDatetime": createdDatetime
    },
    {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getMaps(callback,siteId){
    axios.get(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getMap(callback,siteId, mapId){
    axios.get(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps/${mapId}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  deleteMap(callback,siteId,mapId){
    axios.delete(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps/${mapId}`)
    .then(()=>{
      callback(mapId);
    })
    .catch()
  }
  getDrones(callback,user){
    axios.get(`${this.url}:${this.apiPort}/api/drones?affiliationId=${user.affiliation.id}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getDrone(callback,droneId,num){
    axios.get(`${this.url}:${this.apiPort}/api/drones/${droneId}`)
    .then((data)=>{
      if(num != undefined){
        callback(data, num);
      }
      else{
        callback(data);
      }
    })
    .catch()
  }
  putDrone(droneId,droneStatus){
    axios.patch(`${this.url}:${this.apiPort}/api/drones/${droneId}`,{
      "droneStatus" : droneStatus,
    })
    .then()
    .catch()
  }
  getDronesType(callback,droneType){
    axios.get(`${this.url}:${this.apiPort}/api/drone-types`)
    .then((data)=>{
      callback(data,droneType);
    })
    .catch()
  }
  putDroneType(callback,droneTypeId,body){
    axios.put(`${this.url}:${this.apiPort}/api/drone-types/${droneTypeId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((data)=>{
      callback(data);
    })
    .catch();
  }
  // postMappingMission(callback, siteId, mapId, missionName,createdDatetime){
  //   axios.post(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions`, 
  //   { "facilityMapId": mapId,
  //     "name": missionName,
  //     "createdDatetime":createdDatetime},
  //   {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((data)=>{
  //     callback(data);
  //   })
  //   .catch();
  // }
  // getMappingMissions(callback,siteId,mapId){
  //   axios.get(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions`)
  //   .then((data)=>{
  //     callback(data);
  //   })
  //   .catch()
  // }
  // deleteMappingMission(callback,siteId,mapId,missionId){
  //   axios.delete(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions/${missionId}`)
  //   .then(() => {
  //     callback();
  //   })
  //   .catch();
  // }
  // postMappingWaypoints(callback,siteId,mapId,missionId, body){
  //   axios.post(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints`,
  //   body,
  //   {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(() => {
  //     callback();
  //   })
  //   .catch();
  // }
  // getMappingWaypoints(callback,siteId,mapId,missionId){
  //   axios.get(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints`)
  //   .then((data)=>{
  //     callback(data);
  //   })
  //   .catch()
  // }
  // putMappingWaypoints(callback,siteId,mapId,missionId, body){
  //   axios.put(`${this.url}:${this.apiPort}/api/mapping/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints`,
  //   body,
  //   {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((data) => {
  //     callback(data);
  //   })
  //   .catch();
  // }
  postAlbums(callback,body){
    axios.post(`${this.url}:${this.apiPort}/api/albums`, body)
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  getAlbums(callback,siteId, mapId){
    if(mapId){
      axios.get(`${this.url}:${this.apiPort}/api/albums?facilityId=${siteId}&facilityMapId=${mapId}`)
      .then((data) => {
        callback(data);
      })
      .catch();
    }
    else{
      axios.get(`${this.url}:${this.apiPort}/api/albums?facilityId=${siteId}`)
      .then((data) => {
        callback(data);
      })
      .catch();
    }
  }
  deleteAlbum(callback, albumId){
    axios.delete(`${this.url}:${this.apiPort}/api/albums/${albumId}`)
    .then(() => {
      callback();
    })
    .catch();
  }
  uploadAlbum(callback, albumId, formData, progressGage){
    let url = `${this.url}:${this.apiPort}/api/albums/${albumId}/import`
    axios.post(url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if(progressGage){
            progressGage.style.width = (percentComplete/2) + "%";
          }
        },
      }
    )
    .then(() => {
      this.sse = new SSE(`${url}-progress`, progressGage, callback);
    })
    .catch();
  }
  downloadAlbum(callback,albumId){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${albumId}/export`)
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  getCamera(callback, album){
    axios.get(`${this.url}:${this.apiPort}/api/cameras/${album.cameraId}`)
    .then((data) => {
      callback(data, album);
    })
    .catch();
  }
  postCrackRecognitions(callback, albumId, body){
    axios.post(`${this.url}:${this.apiPort}/api/albums/${albumId}/crack-recognitions`,
    body
    )
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getCrackRecognitions(callback, albumId, albumIndex){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${albumId}/crack-recognitions`)
    .then((data)=>{
      callback(data, albumIndex);
    })
    .catch()
  }
  getOtherRecognitions(callback, albumId, albumIndex){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${albumId}/other-recognitions`)
    .then((data)=>{
      callback(data, albumIndex);
    })
    .catch()
  }
  getSegmentations(callback, album){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${album.id}/crack-recognitions/${album.crackRecognition.id}/segmentations`)
    .then((data)=>{
      callback(data, album);
    })
    .catch()
  }
  putSegmentations(callback, albumId, analysisInfo, crackInfos, crackPoints){
    axios.put(`${this.url}:${this.apiPort}/api/albums/${albumId}/crack-recognitions/${analysisInfo.albumCrackRecognitionId}/segmentations/${analysisInfo.id}/json`,
    { 
      "info": crackInfos,
      "points": crackPoints
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  getDetections(callback, album){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${album.id}/other-recognitions/${album.otherRecognition.id}/detections`)
    .then((data)=>{
      callback(data, album);
    })
    .catch()
  }
  putDetections(callback, albumId, analysisInfo, detedctionInfos){
    axios.put(`${this.url}:${this.apiPort}/api/albums/${albumId}/other-recognitions/${analysisInfo.albumOtherRecognitionId}/detections/${analysisInfo.id}/json`,
    detedctionInfos,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  getPhotos(callback, albumId){
    axios.get(`${this.url}:${this.apiPort}/api/albums/${albumId}/photos`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  deletePhoto(callback, photo){
    axios.delete(`${this.url}:${this.apiPort}/api/albums/${photo.photo.albumId}/photos/${photo.photo.id}`)
    .then((data)=>{
      callback(data, photo);
    })
    .catch()
  }
  getCrackInfos(callback, selectedSegmentation){
    axios.get(`${selectedSegmentation.cracksInfoPath}?${new Date()}`)
    .then((data)=>{
      callback(data,selectedSegmentation);
    })
    .catch()
  }
  getCrackPoints(callback, selectedSegmentation){
    axios.get(`${selectedSegmentation.cracksInfoPath.replace(/\.json$/, '_seg_points.json')}?${new Date()}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getOtherInfos(callback, selectedDetectionInfoPaths){
    for(let i=0; i<selectedDetectionInfoPaths.length; i++){
      axios.get(`${selectedDetectionInfoPaths[i]}?${new Date()}`)
      .then((data)=>{
        callback(data,i);
      })
      .catch()
    }
  }
  postInspectionMissions(callback, siteId, mapId, body, droneNum){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      callback(data, droneNum);
    })
    .catch();
  }
  getInspectionMissions(callback, siteId, mapId){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions`)
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  deleteInspectionMission(callback,siteId,mapId,missionId){
    axios.delete(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}`)
    .then(() => {
      callback();
    })
    .catch();
  }
  postInspectionGroups(callback, siteId, mapId, body, shape, droneNum){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${body.impId}/groups`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      if(callback){
        callback(data,shape,droneNum);
      }
    })
    .catch();
  }
  getInspectionGroups(callback, droneNum, siteId, mapId, missionId){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/groups`)
    .then((data) => {
      callback(data, droneNum, missionId);
    })
    .catch();
  }
  getInspectionShapes(callback, droneNum, siteId, mapId, missionId){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/shapes`)
    .then((data) => {
      callback(data, droneNum, missionId);
    })
    .catch();
  }
  postInspectionRectanlge(body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/shapes/rectangles`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch();
  }
  getInspectionRectangle(callback,shape){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/shapes/rectangles/${shape.shapeId}`)
    .then((data) => {
      callback(data,shape);
    })
    .catch();
  }
  postInspectionCircle(body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/shapes/circles`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch();
  }
  getInspectionCircle(callback,shape){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/shapes/circles/${shape.shapeId}`)
    .then((data) => {
      callback(data,shape);
    })
    .catch();
  }
  postInspectionLine(body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/shapes/lines`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch();
  }
  getInspectionLine(callback,shape){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/shapes/lines/${shape.shapeId}`)
    .then((data) => {
      callback(data,shape);
    })
    .catch();
  }
  postInspectionUnderside(body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/shapes/undersides`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch();
  }
  getInspectionUnderside(callback,shape){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/shapes/undersides/${shape.shapeId}`)
    .then((data) => {
      callback(data,shape);
    })
    .catch();
  }
  postInspectionWaypoints(callback, siteId, mapId, missionId, num, body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      callback(data, num);
    })
    .catch();
  }
  getInspectionWaypoints(callback, droneNum, siteId, mapId, missionId){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints`)
    .then((data) => {
      callback(data, droneNum);
    })
    .catch();
  }
  postInspectionCameraMissions(callback, siteId, mapId, missionId, body){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints/gimbal`, 
    body,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      callback(data);
    })
    .catch();
  }
  getInspectionCameraMissions(callback, droneNum, siteId, mapId, missionId){
    axios.get(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/missions/${missionId}/waypoints/gimbal`)
    .then((data) => {
      callback(data, droneNum);
    })
    .catch();
  }
  startFittingProgram(siteId,mapId,port,userId){
    axios.post(`${this.url}:${this.apiPort}/api/inspection/facilities/${siteId}/maps/${mapId}/fitting`,
    { port : port, userId : userId})
    .then()
    .catch()
  }
  ftpCredentials(callback, siteId, mapId){
    axios.get(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps/${mapId}/ftp-credentials`)
    .then((data) => {
      callback(data);
    })
    .catch();
  }
  postFtpUpload(siteId,mapId){
    axios.post(`${this.url}:${this.apiPort}/api/facilities/${siteId}/maps/${mapId}/upload`)
    .then()
    .catch()
  }
  postDroneDataLog(droneId,data,dateTime){
    axios.post(`${this.url}:${this.apiPort}/api/drone-types/logs`,
    { "droneId" : droneId,
      "droneSystemSeverity" : data.severity,
      "droneSystemMessage" : data.text,
      "createdDatetime" : dateTime},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch()
  }
  getFacilityModels(callback, siteId){
    axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d?facilityId=${siteId}`)
    .then((data)=>{
      callback(data);
    })
    .catch()    
  }
  getModels(callback, facilityModelId){
    axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getModel(callback,facilityModelId,modelId){
    axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  postCaptureInfos(callback,facilityModelId,modelId,name,range,rangeLimit,rotateDeg,verticalCapture,dateTime){
    axios.post(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/editing-parameters`,
    { 
      "name": name,
      "minHeight" : range.minHeight,
      "maxHeight" : range.maxHeight,
      "minWidth" : range.minWidth, 
      "maxWidth" : range.maxWidth,
      "minDepth" : range.minDepth,
      "maxDepth" : range.maxDepth,
      "boxMinWidth" : rangeLimit.boxMinWidth,
      "boxMinHeight" : rangeLimit.boxMinHeight,
      "boxMaxDepth" : rangeLimit.boxMaxDepth,
      "roll" : rotateDeg.x,
      "pitch" : rotateDeg.z,
      "yaw" : rotateDeg.y,
      "vertical" : verticalCapture,
      "roiPartId" : modelId,
      "createdDatetime" : dateTime,
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  getCaptureInfos(callback,facilityModelId,modelId){
    axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/editing-parameters`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }
  deleteCaptureInfo(callback,facilityModelId,modelId,captureInfoId){
    axios.delete(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/editing-parameters/${captureInfoId}`)
    .then((data)=>{
      callback(data,captureInfoId);
    })
    .catch()
  }
  captureProgramStart(facilityModelId,modelId,result){
    axios.post(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets`,
    { "id" : result.id,
      "roiPartId": modelId,
      "minHeight" : result.minHeight,
      "maxHeight" : result.maxHeight,
      "minWidth" : result.minWidth, 
      "maxWidth" : result.maxWidth,
      "minDepth" : result.minDepth,
      "maxDepth" : result.maxDepth,
      "boxMinWidth" : result.boxMinWidth,
      "boxMinHeight" : result.boxMinHeight,
      "boxMaxDepth" : result.boxMaxDepth,
      "roll" : result.roll,
      "pitch" : result.pitch,
      "yaw" : result.yaw,
      "vertical" : result.vertical,
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then()
    .catch()
  }

  getElevationSets(callback, facilityModelId, modelId){
    axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets`)
    .then((data)=>{
      callback(data);
    })
    .catch()
  }

  updateElevation(elevation, facilityModelId, modelId, elevationSetId, elevationId){
    try {
      axios.put(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets/${elevationSetId}/elevations/${elevationId}`, {
        elevationSetId: elevation.elevationSetId,
        degree: elevation.degree,
        name: elevation.name,
        maxColumnCount: elevation.maxColumnCount,
        maxRowCount: elevation.maxRowCount,
        createdDatetime: elevation.createdDatetime,
        wrDatetime: elevation.wrDatetime
      });
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }

  getNetworkMaps(facilityModelId, modelId, elevationSetId){
    return axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets/${elevationSetId}/inspection-network-maps`)
      .then((data) => {
        let networkMapList = [];
        if (data.data.success) {
          const result = data.data.result;
          if (result) {
            for (let i = 0; i < result.length; i++) {
              networkMapList.push(new NetworkMap(result[i]));              
            }
          }
        } else {
          console.error("Failed to retrieve network map data or no data available");
        }
        return networkMapList;  
      })
      .catch((error) => {
        console.error("Failed to fetch network maps", error);
        return [];  
      });
  }
  getInspectionNetworkMap(callback,url){
    axios.get(url,{
      responseType: 'text',
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      alert('SIRIUS Editor에서 작업을 마무리 하십시오.');
    });
  }
  getNetworkMapSummaries(facilityModelId, modelId, elevationSetId, networkMapId){
    return axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets/${elevationSetId}/inspection-network-maps/${networkMapId}/summary`)
      .then((data) => {
        let networkMapSummaryList = [];
        if (data.data.success) {
          const result = data.data.result;
          if (result) {
            for (let i = 0; i < result.length; i++) {
              networkMapSummaryList.push(new NetworkMapSummary(result[i]));
            }
          }
        } else {
          console.error("Failed to retrieve network map summary data or no data available");
        }
        return networkMapSummaryList;  
      })
      .catch((error) => {
        console.error("Failed to fetch network map summaries", error);
        return [];  
      });
  }
  getPrimaryDefects(facilityModelId, modelId, elevationSetId, networkMapId){
    return axios.get(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets/${elevationSetId}/inspection-network-maps/${networkMapId}/primary-defects`)
      .then((data) => {
        let primaryDefectList = [];
        if (data.data.success) {
          const result = data.data.result;
          if (result) {
            for (let i = 0; i < result.length; i++) {
              primaryDefectList.push(new PrimaryDefect(result[i]));
            }
          }
        } else {
          console.error("Failed to retrieve primary defect data or no data available");
        }
        return primaryDefectList;  
      })
      .catch((error) => {
        console.error("Failed to fetch primary defects", error);
        return [];  
      });
  }
  updatePrimaryDefects(primaryDefection, facilityModelId, modelId, elevationSetId, networkMapId, id){
    try {
      axios.put(`${this.url}:${this.apiPort}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/elevation-sets/${elevationSetId}/inspection-network-maps/${networkMapId}/primary-defects/${id}`, {
        inspectionNetworkMapId: primaryDefection.inspectionNetworkMapId,
        type: primaryDefection.type,
        currentState: primaryDefection.currentState,
        cause: primaryDefection.cause,
        solution: primaryDefection.solution,
        thumbnailsUri: primaryDefection.thumbnailsUri,
        createdDatetime: primaryDefection.createdDatetime,
        wrDatetime: primaryDefection.wrDatetime
      });
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }
  getStorage(callback,userId, albumBody, file){
    axios.get(`${this.url}:${this.apiPort}/api/users/${userId}/storage`)
    .then((data)=>{
      if(albumBody){
        callback(data, albumBody, file);
      }
      else{
        callback(data);
      }
    })
    .catch()
  }
}

export default RESTAPI
