<template>
  <div class="home_left_layout">
    <div class="home_site_list_title_contatiner">
      <div class="home_site_list_title">시설물</div>
      <div class="home_add_site_button button" @click="clickSiteCreate"></div>
    </div>
    <div class="home_search_bar_container">
      <div class="home_search_bar">
        <input
          type="text"
          class="home_serach_input korean"
          @input="inputSearch($event, 12)"
        />
      </div>
    </div>
    <div class="home_site_list_container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["createSite", "filterSite"],
  methods: {
    clickSiteCreate() {
      this.$emit("createSite");
    },
    inputSearch(event, limit) {
      if (event.target.value.length > limit) {
        this.$store.commit(
          "openAlert",
          `입력 값은 ${limit}자를 초과할 수 없습니다.`
        );
        event.target.value = event.target.value.substr(0, limit);
      }
      this.$emit("filterSite", event);
    },
  },
};
</script>

<style lang="scss">
.home_left_layout {
  position: fixed;
  z-index: 10;
  top: 70px;
  left: 0px;
  width: calc(100% / 12 * 3 - 15px);
  height: calc(100% - 130px);
  margin: 30px 0px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(17, 17, 17, 0.5);
  border-radius: 10px;
  max-width: 350px;
}

.home_site_list_title_contatiner {
  height: calc(100% / 16);
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  background-color: transparent;
}

.home_site_list_title {
  display: flex;
  height: 100%;
  width: calc(100% - 30px);
  align-items: center;
  color: $main_white;
  font-size: 1.8rem;
}

.home_add_site_button {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/circlePlus.svg");
  background-size: 15px, 15px;
}

.home_add_site_button:hover {
  background-image: url("@/../public/images/circlePlus_hover.svg");
  background-size: 20px, 20px;
}

.home_search_bar_container {
  height: calc(100% / 16);
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
}
.home_search_bar {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: $main_box_shadow;
  border-radius: 5px;
  background: url("@/../public/images/search.svg") no-repeat right 10px center
    rgb(23, 23, 23);
  background-size: 13px 13px;
}

.home_serach_input {
  height: 90%;
  width: calc(100% - 10px);
  margin-left: 10px;
  background-color: transparent;
  color: $main_color;
  font-size: 1.6rem;
  border: none;
}
.home_serach_input::placeholder {
  color: $main_color;
  font-size: 1.6rem;
}
.home_serach_input:focus {
  outline: none;
  border: none;
}
.home_site_list_container {
  height: calc(100% / 8 * 7 - 20px);
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.home_site_list_container::-webkit-scrollbar {
  width: 4px;
}
/* 스크롤바 트랙 스타일 */
.home_site_list_container::-webkit-scrollbar-track {
  background: transparent;
}
/* 스크롤바 손잡이 스타일 */
.home_site_list_container::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.home_site_list_container::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
</style>
