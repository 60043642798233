<template>
  <div id="form_content_wrap">
    <form class="form_wrap">
      <div class="content_area custom_scrollbar">
        <div class="profile">
          <div
            class="profile_image_box"
            :class="{
              no_image: !uploadedImageUrl || isDeleted,
            }"
            :style="[
              uploadedImageUrl && !isDeleted
                ? { backgroundImage: `url(${uploadedImageUrl})` }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
        </div>
        <!-- 업로드한 이미지가 있거나, 이미 프로필 이미지가 존재할 때에만 보여지도록 -->
        <div class="delete_area" v-if="uploadedImageUrl">
          <button type="button" @click="removeProfileImage">
            프로필 이미지 삭제
          </button>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="required">이름</label>
            <div class="input_container focus">
              <input
                class=""
                ref="name"
                type="text"
                placeholder="이름을 입력하세요."
                @input="inputCheck($event, 10)"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="required">권한</label>
            <DefaultSelectBox
              :className="`focus full`"
              :isOptionOpen="isOptionOpen"
              :defaultName="`권한을 선택하세요.`"
              :selectedName="clickedOptionValue"
              @update:isOptionOpen="isOptionOpen = $event"
              @controlOptionToggle="controlOptionToggle"
            >
              <ul>
                <li
                  v-for="(item, index) in adminArray"
                  :key="item + index"
                  @click="handleClickOption(item)"
                  :class="item === this.clickedOptionValue ? 'active' : ''"
                >
                  <p>{{ item }}</p>
                </li>
              </ul>
            </DefaultSelectBox>
          </div>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="required">부서</label>
            <div class="input_container focus">
              <input
                class=""
                ref="field"
                type="text"
                placeholder="부서를 입력하세요."
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="required">직급</label>
            <div class="input_container focus">
              <input
                class=""
                ref="position"
                type="text"
                placeholder="직급을 입력하세요."
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <p class="gray_text">
          * 부서 및 직급은 정확히 입력해 주세요. 예: '마케팅팀'과 '마케팅'은
          다른 항목으로 처리될 수 있습니다.
        </p>
        <label class="required">아이디</label>
        <div class="input_container focus">
          <input
            class=""
            ref="userId"
            type="text"
            placeholder="아이디를 입력하세요."
            @input="inputCheck($event, 10)"
            autocomplete="off"
          />
        </div>
        <label class="required">비밀번호</label>
        <div class="input_container focus">
          <input
            class=""
            type="password"
            ref="password"
            placeholder="비밀번호를 입력하세요."
            autocomplete="off"
          />
          <button
            class="eye_button"
            :class="isPasswordShow ? 'on' : ''"
            type="button"
            @click="showPassword($event, 'password')"
          ></button>
        </div>
        <label class="">연락처</label>
        <div class="input_container focus">
          <input
            class=""
            ref="contact"
            type="text"
            placeholder="숫자만 입력해 주세요."
            autocomplete="off"
            v-model="defaultUserContactValue"
            @input="onInputChange"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitAdd" class="default_bg_button" type="button">
          추가
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          취소
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { changeSiblingInputType } from "@/shared/util/changeSiblingInputType";
// import { checkAuthorizeLimit } from "@/shared/util/checkAuthorizeLimit";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { filterNonNumeric } from "@/shared/util/filterNonNumeric";
import { mapState } from "vuex";
import { changeRoleToEnglish } from "@/shared/util/changeRoleToEnglish";
import CryptoJS from "crypto-js";

export default {
  name: "UserAddFormContent",
  components: { DefaultSelectBox },
  computed: {
    ...mapState(["user"]),
  },
  emits: ["handleUploadFile", "handleAddUser", "handleRemoveProfileImage"],
  props: {
    uploadedImageUrl: {
      type: String,
      required: false,
    },
    isDeleted: {
      type: Boolean,
      required: false,
    },
    userListArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      adminArray: ["일반", "매니저"],
      isPasswordShow: false,
      isPasswordConfirmShow: false,
      isOptionOpen: false,
      clickedOptionValue: null,
      imageFileInfo: null,
      defaultUserContactValue: null,
    };
  },
  methods: {
    inputCheck(event, limit) {
      checkInputLimit(event, limit, this.$store);
    },
    showPassword(event, target) {
      if (target === "password") {
        this.isPasswordShow = !this.isPasswordShow;
      } else {
        this.isPasswordConfirmShow = !this.isPasswordConfirmShow;
      }
      changeSiblingInputType(event);
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isOptionOpen = !this.isOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickOption(value) {
      this.isOptionOpen = false;
      this.clickedOptionValue = value;
    },
    /**
     * 파일 업로드
     */
    uploadFile(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        const basename = file.name.replace(/\.[^/.]+$/, "");

        // 수정된 특수 문자 정규 표현식
        let regExp = /[`~!@#$%^&*|\\'";:/?()]/gi;

        // 특수 문자가 포함되어 있는지 체크
        if (regExp.test(basename)) {
          return this.$store.commit(
            "openAlert",
            `이미지 파일명에 특수 문자는 제거하고 업로드해 주세요.`
          );
        }

        this.imageFileInfo = file;
        this.$emit("handleUploadFile", file);
      }
    },
    /**
     * 사용자 정보 추가 기능
     */
    submitAdd() {
      let userName = this.$refs.name.value;
      let userRole =
        this.clickedOptionValue === null
          ? null
          : changeRoleToEnglish(this.clickedOptionValue);
      let userId = this.$refs.userId.value;
      let userPw = this.$refs.password.value;
      let userContact = this.$refs.contact.value;
      let userField = this.$refs.field.value;
      let userPosition = this.$refs.position.value;

      const userInfo = {
        adminId: this.user.id,
        userLoginId: userId ? userId : null,
        userPassword: userPw ? CryptoJS.SHA256(userPw).toString() : null,
        fullName: userName ? userName : null,
        role: userRole ? userRole : null,
        contact: userContact ? userContact : null,
        affiliation: {
          departmentName: userField ? userField : null,
          rankName: userPosition ? userPosition : null,
        },
      };

      // if (
      //   // 일반/매니저 권한 리밋 확인
      //   !checkAuthorizeLimit(this.userListArray, userRole, this.$store)
      // ) {
      //   return;
      // }

      if (
        !userName ||
        userRole === null ||
        !userId ||
        !userPw ||
        !userField ||
        !userPosition
      ) {
        return this.$store.commit(
          "openAlert",
          `필수 항목을 모두 입력해 주세요.`
        );
      }

      this.$emit("handleAddUser", userInfo);
    },
    /**
     * 프로필 이미지 삭제
     */
    removeProfileImage() {
      this.$emit("handleRemoveProfileImage");
    },
    onInputChange(event) {
      // 숫자가 아닌 입력값을 제외시킨다.
      this.defaultUserContactValue = filterNonNumeric(event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/component/inputs";

.profile {
  position: relative;
  @include flexbox;
  margin-bottom: 12px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  .profile_image_box {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-size: contain;
    background-repeat: no-repeat;

    &.no_image {
      @include basicProfile(70px, 70px);
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .camera_icon {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 20px;
      height: 20px;
      background: #000 url("@/../public/images/camera.svg") no-repeat center;
      background-size: 60%;
      border-radius: 50px;
      border: 1px solid $main_white;
    }
  }
}

label {
  position: relative;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 12px;
  display: inline-block;
  margin-top: 18px;

  & ~ label {
    margin-top: 18px;
  }
}

p.gray_text {
  font-size: 1.4rem;
  margin-top: 12px;
}

.delete_area {
  @include flexbox(center, flex-end, row);
  margin: 12px 0;

  button {
    text-decoration: underline;
    color: $gray_9fa6b2;

    &.red_text {
      color: $red_ff7070;
    }
  }
}
</style>
