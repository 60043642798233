<template>
  <div class="main korean">
    <MainHeaderVue :navigator="navigator" />
    <MainLoadingVue ref="mainLoading" />
    <div
      ref="viewer"
      class="measure_view"
      @mousemove="handleMouseMove"
      @mousedown="handleMouseDown"
    >
      <div ref="magnifier" class="magnifier"></div>
    </div>
    <MeasureInfoVue
      :measureMethod="measureMethod"
      @changeMethod="handleMesureMethod"
      @deleteMeasure="deleteMeasure"
    ></MeasureInfoVue>
    <MeasureRangeModalVue
      v-if="measureMethod == 'volume'"
      :isXYZClicked="isXYZClicked"
      :rangeAxis="rangeAxis"
      :range="range"
      :rangeLimit="rangeLimit"
      @setRangeAxis="setRangeAxis"
      @setRange="setRange"
    />
    <MeasureVolumeButtonVue
      v-if="measureMethod == 'volume'"
      :isXYZClicked="isXYZClicked"
      @changeXYZ="handleXYZ"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import ModelLoader from "@/module/ModelLoader.js";

import MainHeaderVue from "../Common/MainHeader.vue";
import MainLoadingVue from "../Common/MainLoading.vue";
import MeasureInfoVue from "./MeasureInfo.vue";
import MeasureRangeModalVue from "./MeasureRangeModal.vue";
import MeasureVolumeButtonVue from "./MeasureVolumeButton.vue";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Range from "@/model/Range.js";

export default {
  data() {
    return {
      navigator: "measure",
      measureMethod: null,
      range: new Range(),
      rangeLimit: new Range({
        minHeight: 0,
        maxHeight: 100,
        minWidth: 0,
        maxWidth: 100,
        minDepth: 0,
        maxDepth: 100,
        boxMinHeight: 0,
        boxMinWidth: 0,
        boxMaxDepth: 100,
      }),
      rangeAxis: 0,
      isXYZClicked: false,
    };
  },
  computed: {
    ...mapState(["user", "api", "siteList", "selectedSite"]),
  },
  components: {
    MainHeaderVue,
    MainLoadingVue,
    MeasureInfoVue,
    MeasureRangeModalVue,
    MeasureVolumeButtonVue,
  },
  methods: {
    handleKeydown(event) {
      if (event.key === "Shift") {
        this.loader.getShiftKey(true);
      }
    },
    handleKeyUp(event) {
      if (event.key === "Shift") {
        this.loader.getShiftKey(false);
      }
    },
    handleMouseMove(event) {
      if (this.measureMethod) this.loader.onMouseMove(event);
    },
    handleMouseDown(event) {
      event.preventDefault();
      this.loader.onMouseDown(event);
    },
    getSites() {
      if (!this.siteList) {
        this.api.getSites(this.user.id, this.getSitesCallback);
      } else {
        this.getSite();
      }
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          siteList.push(new Site(result[i]));
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      let setSelectedSite = siteList.find(
        (site) => site.id == this.$route.params.siteId
      );
      this.$store.dispatch("setSelectedSite", setSelectedSite);
      this.getSite();
    },
    getSite() {
      if (
        !this.selectedSite ||
        this.selectedSite.id != this.$route.params.siteId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.siteId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedSite", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.siteId);
      this.api.getFacilityModels(
        this.getFacilityModelsCallback,
        this.$route.params.siteId
      );
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.dispatch("updateMap", map);
      }
    },
    getFacilityModelsCallback(data) {
      this.facilityModel = data.data.result[0];
      // console.log(this.facilityModel.facilityModel3DUrl);
      this.loadModel("/kimcheon/kimcheon.glb");
    },
    loadModel(url) {
      this.loader = new ModelLoader(
        this.$refs.viewer,
        null,
        this.$refs.mainLoading,
        this.$refs.magnifier
      );
      this.loader.load(url, this.loadModelCallback);
    },
    loadModelCallback() {
      console.log("load Callback");
    },
    setRangeAxis(number) {
      this.rangeAxis = number;
      if (this.measureMethod == "volume") this.loader.setPlaneVisible(number);
    },
    handleMesureMethod(method) {
      this.loader.removeDrawings();
      if (this.measureMethod == "volume") {
        this.loader.applyOriginalColor();
        this.loader.setBoundingBoxVisibility(false);
      }
      if (this.measureMethod == method) {
        this.measureMethod = null;
      } else {
        this.measureMethod = method;
        switch (method) {
          case "length":
            this.loader.setLineMeasuring();
            break;
          case "area":
            this.loader.setAreaMeasuring();
            break;
          case "volume":
            this.isXYZClicked = true;
            this.loader.resetMinMaxRange();
            this.setResetRange();
            this.setRangeLimit();
            this.loader.setBoundingBoxVisibility(true);
            this.loader.setVolumeMeasuring();
            break;
          case "angle":
            this.loader.setAngleMeasuring();
            break;
          default:
            break;
        }
      }
    },
    setRangeLimit() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();

      this.rangeLimit.maxHeight = parseFloat(heightRangeLimit.max.toFixed(1));
      this.rangeLimit.minHeight = parseFloat(heightRangeLimit.min.toFixed(1));
      this.rangeLimit.maxWidth = parseFloat(widthRangeLimit.max.toFixed(1));
      this.rangeLimit.minWidth = parseFloat(widthRangeLimit.min.toFixed(1));
      this.rangeLimit.maxDepth = parseFloat(depthRangeLimit.max.toFixed(1));
      this.rangeLimit.minDepth = parseFloat(depthRangeLimit.min.toFixed(1));
      this.rangeLimit.boxMinHeight = parseFloat(heightRangeLimit.boxMin);
      this.rangeLimit.boxMinWidth = parseFloat(widthRangeLimit.boxMin);
      this.rangeLimit.boxMaxDepth = parseFloat(depthRangeLimit.boxMax);
    },
    setResetRange() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();
      this.setRange("minHeight", heightRangeLimit.min.toFixed(1));
      this.setRange("maxHeight", heightRangeLimit.max.toFixed(1));
      this.setRange("minWidth", widthRangeLimit.min.toFixed(1));
      this.setRange("maxWidth", widthRangeLimit.max.toFixed(1));
      this.setRange("minDepth", depthRangeLimit.min.toFixed(1));
      this.setRange("maxDepth", depthRangeLimit.max.toFixed(1));
    },
    setRange(property, value) {
      switch (property) {
        case "minHeight":
          this.range.minHeight = parseFloat(value);
          break;
        case "maxHeight":
          this.range.maxHeight = parseFloat(value);
          break;
        case "minWidth":
          this.range.minWidth = parseFloat(value);
          break;
        case "maxWidth":
          this.range.maxWidth = parseFloat(value);
          break;
        case "minDepth":
          this.range.minDepth = parseFloat(value);
          break;
        case "maxDepth":
          this.range.maxDepth = parseFloat(value);
          break;
        default:
          break;
      }
      if (this.isXYZClicked) this.loader.applyVolumeColor(this.range);
      else if (!this.isXYZClicked)
        this.loader.applyVolumePolygonColor(this.range);
    },
    deleteMeasure() {
      this.loader.deleteMeasurements();
    },
    handleXYZ(boolean) {
      this.loader.applyOriginalColor();
      if (boolean) {
        this.loader.removeDrawingCropLine();
        this.loader.applyVolumeColor(this.range);
      } else {
        this.setRangeAxis(0);
        this.loader.applyVolumePolygonColor(this.range);
      }
      this.isXYZClicked = boolean;
      this.loader.setVolumeMeasuring();
    },
  },
  mounted() {
    this.getSites();
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeUnmount() {
    if (this.loader) {
      this.loader.clear();
    }
  },
};
</script>

<style lang="scss">
.measure_view {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.magnifier {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 2px rgba(1, 250, 254, 0.5);
  z-index: 10;
}
</style>
